<td class="text-center td-table-usuarios">
  <mat-checkbox [disabled]="objChkBox.disabled" [(ngModel)]="checked" (ngModelChange)="chkItemPartidaSelected_changeEvent($event)" ></mat-checkbox>
</td>

<td class="text-center td-table-usuarios">
  <button class="datatable-button" [disabled]="btnAccion.disabled">
    <mat-icon matTooltip="Eliminar" class="datatable-button-icon" (click)="btnPartidaEliminar_clickEvent()">clear</mat-icon>
  </button>
</td>

<td class="td-table-usuarios">
  <div class="bs-content">
    <div class="bs-row">
      <div class="bs-col-10 unidad-autocomplete" [style.width]="'calc(100% - 70px)'">
        <input
          app-input-autocomplete #txtUnidad [display]="['numeroEconomico']"
          type="text"
          class="table-input"
          placeholder="Unidad"
          [formControl]="fcUnidad"
          (keyup)="txtUnidad_keyUpEvent($event)"
          [matAutocomplete]="unidad"
          (focusout)="txtUnidad_OnFocusOutEvent()"
          style="width:100%;">
        <mat-icon class="input-icon-puesto" *ngIf="!txtUnidad.loading && toggleUnidad" >{{txtUnidad.getIcon()}}</mat-icon>
        <mat-spinner class="input-spinner-puesto" *ngIf="txtUnidad.loading" [diameter]="18" ></mat-spinner>
        <mat-autocomplete autoActiveFirstOption="false" #unidad="matAutocomplete" (optionSelected)="unidadSelected_selectedEvent($event.option.value)">
          <mat-option class="autocomplete-option-container" [disabled]="itemUnidad.idUnidadEstatus !=1" *ngFor="let itemUnidad of lstUnidades" [value]="itemUnidad.idUnidad">
            <div class="bs-content autocomplete-content">
              <div class="bs-row autocomplete-row">
                <div class="bs-col-1 autocomplete-col-icon-unidad"><span class="material-icons autocomplete-icon">local_shipping</span></div>
                <div class="bs-col">
                  <div class="bs-row"><div class="bs-col item-titule">{{itemUnidad.numeroEconomico}}</div></div>
                  <div class="bs-row"><div class="bs-col item-subtitule">{{itemUnidad.unidadTipo}}</div></div>
                </div>
                <div class="bs-col-4"><span class="badge" [class]="['unidad-estatus-' + itemUnidad.idUnidadEstatus]">{{itemUnidad.unidadEstatus}}</span></div>
              </div>
            </div>
          </mat-option>
        </mat-autocomplete>
      </div>
      <div class="bs-col icon-button-unidad">
        <button type="button" [matTooltip]="(toggleUnidad)?'Sin unidad':'Con unidad'" [ngClass]="(toggleUnidad) ? 'toggle-on' : 'toggle-off'" (click)="btnUnidadToggle_clickEvent()">
          <span *ngIf="toggleUnidad" class="material-icons">local_shipping</span>
          <div *ngIf="!toggleUnidad" class="btn-off-unidad"></div>
        </button>

        <button class="toggle-on" [class.principal]="boolUnidadPrincipal" [class.required]="boolValidUnidadPrincipal" [disabled]="(!boolUnidadPrincipal && hasPrincipal) || !toggleUnidad" type="button" matTooltip="Unidad principal" (click)="btnUnidadPrincipal_clickEvent()">
          <span class="material-icons">verified</span>
        </button>
      </div>
    </div>
    <div class="card-info-content" *ngIf="!boolunidadInfo">
      <div class="card-body">
        <div class="card-text">
          <div class="card-label">Tipo de unidad</div>
          <div class="card-field">{{objUnidad?.unidadTipo}}</div>
        </div>
        <div class="card-text">
          <div class="card-label">Estatus</div>
          <div class="card-field"><span class="badge" [class]="['unidad-estatus-' + objUnidad?.idUnidadEstatus]">{{objUnidad?.unidadEstatus}}</span></div>
        </div>
        <div class="card-text card-input-content" *ngIf="Globals.exist(objUnidad?.hasOdometro) && objUnidad.hasOdometro">
          <div class="card-label">Odómetro</div>
          <div class="card-field" style="width: 350px;">{{objUnidad?.odometroNatural}}</div>
          <div class="card-input">
            <span class="material-icons prefix" *ngIf="boolOdometro" matTooltip="El valor no puede ser menor">warning</span>
            <input class="table-input iconPrefix text-right" type="text" [formControl]="fcOdometro" matInput mask="separator.2" thousandSeparator="," maxlength="127" autocomplete="off" (keyup)="inputOdometro_changeEvent()" [class.required]="fcOdometro.hasError('required')  && fcOdometro.touched" style="padding-right: 24px">
            <span class="suffix">Kms.</span>
          </div>
        </div>
        <div class="card-text card-input-content" *ngIf="Globals.exist(objUnidad?.hasHorometro) && objUnidad.hasHorometro">
          <div class="card-label">Horómetro</div>
          <div class="card-field" style="width: 350px;">{{objUnidad?.horometroNatural}}</div>
          <div class="card-input">
            <span class="material-icons prefix" *ngIf="boolHorometro" matTooltip="El valor no puede ser menor">warning</span>
            <input class="table-input iconPrefix text-center" type="text" [formControl]="fcHoromatro" mask="separator.2" thousandSeparator="," matInput maxlength="127" autocomplete="off" (keyup)="inputHoroometro_changeEvent()" [class.required]="fcHoromatro.hasError('required')  && fcHoromatro.touched">
            <span class="suffix">Hrs.</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</td>

<td class="td-table-usuarios" style="padding: 0">
  <div class="datatable-responsive layout">
    <div class="bs-content">
      <div class="bs-row" *ngFor="let itemUnidad_usuario of lstUnidad_Usuarios; let index = index;">
        <div class="bs-col-1" style="width: 30px">
          <button class="datatable-button" [disabled]="btnAccion.disabled" (click)="eliminarUsuario_clickEvent(index)">
            <mat-icon matTooltip="Eliminar" class="datatable-button-icon">clear</mat-icon>
          </button>
        </div>
        <div class="bs-col input-puesto">
          <input
            app-input-autocomplete #lstTxtUsuarioOperacion [display]="['nombreCompleto']"
            type="text"
            class="table-input"
            placeholder="Operador"
            [disabled]="itemUnidad_usuario.disabled"
            [(ngModel)]="itemUnidad_usuario.operador"
            (keyup)="txtUsuario_keyUpEvent($event, index)"
            [matAutocomplete]="operador"
            (ngModelChange)="operadorValidate_changeEvent(index)"
            [class.required]="!itemUnidad_usuario.operadorValidate"
            (focusout)="txtOperador_OnFocusOutEvent(index)"
            style="width:100%;">
          <mat-icon class="input-icon-puesto" *ngIf="!lstTxtUsuarioOperacion.loading" >{{lstTxtUsuarioOperacion.getIcon()}}</mat-icon>
          <mat-spinner class="input-spinner-puesto" *ngIf="lstTxtUsuarioOperacion.loading" [diameter]="18" ></mat-spinner>
          <mat-autocomplete autoActiveFirstOption="false" [panelWidth]="'300px'" #operador="matAutocomplete" (optionSelected)="usuarioSelected_selectedEvent($event.option.value, index)">
            <mat-option class="autocomplete-option-container" [disabled]="itemUsuario.idUsuarioOperadorEstatus !=1" *ngFor="let itemUsuario of lstUsuarios" [value]="itemUsuario.idUsuario">
              <div class="bs-content autocomplete-content">
                <div class="bs-row autocomplete-row">
                  <div class="bs-col-1 autocomplete-col-icon"><span class="material-icons autocomplete-icon">person</span></div>
                  <div class="bs-col">
                    <div class="bs-row"><div class="bs-col item-titule">{{itemUsuario.nombreCompleto}}</div></div>
                    <div class="bs-row"><div class="bs-col item-subtitule">{{itemUsuario?.puesto}}</div></div>
                  </div>
                  <div class="bs-col-4"><span class="badge" [class]="['estatus-' + itemUsuario.idUsuarioOperadorEstatus]">{{itemUsuario?.usuarioOperadorEstatus}}</span></div>
                </div>
              </div>
            </mat-option>
          </mat-autocomplete>
        </div>
        <div class="bs-col input-puesto">
          <input
            app-input-autocomplete #lstTxtPuesto [display]="['ordenServicioMiembroUsuarioPuesto']"
            type="text"
            class="table-input"
            placeholder="Puesto"
            [disabled]="itemUnidad_usuario.disabled"
            [(ngModel)]="itemUnidad_usuario.puesto"
            (keyup)="txtPuesto_keyUpEvent($event, index)"
            [matAutocomplete]="puesto"
            (ngModelChange)="puestoValidate_changeEvent(index)"
            [class.required]="!itemUnidad_usuario.puestoValidate"
            style="width:100%;">
          <mat-icon class="input-icon-puesto" *ngIf="!lstTxtPuesto.loading" >{{lstTxtPuesto.getIcon()}}</mat-icon>
          <mat-spinner class="input-spinner-puesto" *ngIf="lstTxtPuesto.loading" [diameter]="18" ></mat-spinner>
          <mat-autocomplete autoActiveFirstOption="false" #puesto="matAutocomplete" (optionSelected)="puestoSelected_selectedEvent($event.option.value, index)">
            <mat-option class="autocomplete-option-container"
                        *ngFor="let itemOrdenServicioMiembro_UsuarioPuesto of filteredlstOrdenesServiciosMiembros_UsuariosPuestos; first as isFirst"
                        [disabled]="isFirst && !boolUnidadPrincipal && hasPrincipal"
                        [value]="itemOrdenServicioMiembro_UsuarioPuesto.idOrdenServicioMiembroUsuarioPuesto">
              <div class="bs-content autocomplete-content">
                <div class="bs-row" style="height: 100%; line-height: 27px;">
                  <div class="bs-col">
                    <div class="bs-row"><div class="bs-col item-titule">{{itemOrdenServicioMiembro_UsuarioPuesto.ordenServicioMiembroUsuarioPuesto}}</div></div>
                  </div>
                </div>
              </div>
            </mat-option>
          </mat-autocomplete>
        </div>
      </div>
      <div class="bs-row">
        <div class="bs-col">
          <div class="add-usuario" (click)="agregarUsuario_clickEvent()">Agregar</div>
        </div>
      </div>
    </div>
  </div>
</td>
