<div class="mat-dialog-title">
  <div class="title">Modificar cotización</div>
  <div class="spacer"></div>
  <div class="buttons">
    <button type="button" class="title-button" (click)="close()">
      <mat-icon class="title-button-icon">clear</mat-icon>
    </button>
  </div>
</div>

<div class="mat-dialog-body">
  <div class="bs-content-full-area" [style.height.%]="100">
    <div class="bs-sidebar-left" [style.width.px]="65">
      <div class="bs-content">
        <div class="row">
          <div class="col text-center">
            <span class="material-icons" [style.fontSize.px]="45">request_quote</span>
          </div>
        </div>
        <div class="bs-row">
          <div class="bs-col">
            <mat-selection-list [(ngModel)]="objScene" [multiple]="false">
              <mat-list-option class="cotizacion-mat-list-option" [value]="'SOLICITUD'" matTooltip="Solicitud" matTooltipPosition="right"><mat-icon class="item-option-icon">person</mat-icon></mat-list-option>
              <mat-list-option class="cotizacion-mat-list-option" [value]="'REQUERIMIENTOS'" matTooltip="Requerimientos" matTooltipPosition="right"><mat-icon class="item-option-icon">article</mat-icon></mat-list-option>
              <mat-list-option class="cotizacion-mat-list-option" [value]="'PROPUESTA'" matTooltip="Propuesta económica" matTooltipPosition="right"><mat-icon class="item-option-icon">paid</mat-icon></mat-list-option>
              <mat-list-option class="cotizacion-mat-list-option" [value]="'COMENTARIOS'" matTooltip="Condiciones" matTooltipPosition="right"><mat-icon class="item-option-icon">check_box</mat-icon></mat-list-option>
              <mat-list-option class="cotizacion-mat-list-option" [value]="'DOCUMENTACION'" matTooltip="Documentación adjunta" matTooltipPosition="right"><mat-icon class="item-option-icon">folder</mat-icon></mat-list-option>
            </mat-selection-list>
          </div>
        </div>
      </div>
    </div>

    <div class="bs-viewport">
      <!--   INICIO SOLICITUD   -->
      <div class="datatable-responsive layout" [hidden]="objScene[0] != 'SOLICITUD'">
        <div class="bs-content">
          <div class="bs-row">
            <div class="bs-col">
              <form [formGroup]="fgCotizacionesUpdateSolicitud" #formDirective="ngForm">
                <div class="bs-content">
                  <div class="bs-row">
                    <div class="bs-col">
                      <div class="section-title"><span>Información de solicitud</span></div>
                    </div>
                  </div>
                  <div class="bs-row">
                    <div class="bs-col-9">
                      <mat-form-field class="example-full-width" appearance="legacy">
                        <mat-label>Solicitante</mat-label>
                        <input app-input-autocomplete #txtUsuarioSolicitud [display]="['nombreCompleto']" type="text" placeholder="Nombre del usuario" aria-label="Solicitante" matInput formControlName="solicitudUsuario" required (keyup)="txtUsuario_keyUpEvent($event)" [matAutocomplete]="autoSolicitudUsuario" (focusout)="txtUsuario_OnFocusOutEvent()">
                        <mat-icon matSuffix *ngIf="!txtUsuarioSolicitud.loading" >{{txtUsuarioSolicitud.getIcon()}}</mat-icon>
                        <mat-spinner matSuffix *ngIf="txtUsuarioSolicitud.loading" style="margin:0 auto" [diameter]="18" ></mat-spinner>
                        <mat-autocomplete autoActiveFirstOption="false" #autoSolicitudUsuario="matAutocomplete" (optionSelected)="getUsuarioSelected_selectedEvent($event.option.value)" >
                          <mat-option *ngFor="let itemUsuario of lstUsuarios" [value]="itemUsuario.idUsuario">
                            <img class="example-option-img" [src]="objApplicationConfig.ENDPOINT + 'api/usuarios/foto/' + itemUsuario.idUsuario + '/64'">
                            <span class="title-option">{{itemUsuario.nombreCompleto}}</span>
                            <span class="subtitle-option" >{{itemUsuario.email}}</span>
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                    </div>
                    <div class="bs-col-3">
                      <mat-form-field appearance="legacy">
                        <mat-label>Fecha de solicitud</mat-label>
                        <input matInput [matDatepicker]="solicitudFecha" formControlName="solicitudFecha" required>
                        <mat-datepicker-toggle matSuffix [for]="solicitudFecha"></mat-datepicker-toggle>
                        <mat-datepicker #solicitudFecha></mat-datepicker>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="bs-row">
                    <div class="bs-col">
                      <mat-form-field>
                        <mat-label>Empresa</mat-label>
                        <mat-select formControlName="empresa" required (selectionChange)="getEmpresaSelected_selectedEvent($event.value)">
                          <mat-option *ngFor="let itemEmpresa of lstEmpresas" [value]="itemEmpresa.idEmpresa">{{itemEmpresa.nombreComercial}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="bs-col">
                      <mat-form-field>
                        <mat-label>Sucursal</mat-label>
                        <mat-select formControlName="sucursal" required>
                          <mat-option *ngFor="let itemSucursal of lstSucursalesFiltered" [value]="itemSucursal.idSucursal">{{itemSucursal.sucursal}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="bs-row" [style.paddingTop.px]="15">
                    <div class="bs-col">
                      <div class="section-title"><span>Información de cliente</span></div>
                    </div>
                  </div>

                  <div class="bs-row">
                    <div class="bs-col">
                      <mat-form-field class="example-full-width" appearance="legacy">
                        <mat-label>Cliente</mat-label>
                        <input app-input-autocomplete #txtCliente [display]="['nombreComercial']" type="text" placeholder="Nombre Comercial" aria-label="Cliente" matInput formControlName="clienteNombreComercial" required (keyup)="txtClientes_keyUpEvent($event)" [matAutocomplete]="autoSolicitudCliente" (focusout)="txtCliente_OnFocusOutEvent()">
                        <mat-icon matSuffix *ngIf="!txtCliente.loading" >{{txtCliente.getIcon()}}</mat-icon>
                        <mat-spinner matSuffix *ngIf="txtCliente.loading" style="margin:0 auto" [diameter]="18" ></mat-spinner>
                        <mat-autocomplete autoActiveFirstOption="false" #autoSolicitudCliente="matAutocomplete" (optionSelected)="getClienteSelected_selectedEvent($event.option.value)" >
                          <mat-option *ngFor="let itemCliente of lstClientes" [value]="itemCliente.idCliente">
                            <span class="title-option">{{itemCliente.nombreComercial}}</span>
                            <span class="subtitle-option" >{{itemCliente.razonSocial}} - {{itemCliente.rfc}}</span>
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="bs-row" *ngIf="txtCliente.objValue != null">
                      <div class="content-card" style="padding: 0 0 0 5px">
                          <div class="card-row">
                              <div class="card-col cotizacion-card-info">
                                  <div class="card-row"><div class="card-col-label card-label"><mat-icon class="info-icon">store</mat-icon>Nombre Comercial</div></div>
                                  <div class="card-row"><div class="card-col-label card-label"><mat-icon class="info-icon">description</mat-icon>Raz&oacute;n Social</div></div>
                                  <div class="card-row" *ngIf="objValueValidate(1,'telefono')"><mat-icon class="info-icon">phone</mat-icon><div class="card-col-label card-label">Teléfono</div></div>
                                  <div class="card-row" *ngIf="objValueValidate(1,'email')"><mat-icon class="info-icon">email</mat-icon><div class="card-col-label card-label">Correo</div></div>
                                  <div class="card-row" *ngIf="objValueValidate(1,'direccion')"><mat-icon class="info-icon">place</mat-icon><div class="card-col-label card-label">Dirección</div></div>
                              </div>
                              <div class="card-col">
                                  <div class="card-row"><div class="card-col-fiel card-fiel">{{txtCliente.objValue?.nombreComercial}}</div></div>
                                  <div class="card-row"><div class="card-col-fiel card-fiel">{{txtCliente.objValue?.razonSocial}}</div></div>
                                  <div class="card-row" *ngIf="objValueValidate(1,'telefono')"><div class="card-col-fiel card-fiel">{{txtCliente.objValue.telefono}}</div></div>
                                  <div class="card-row" *ngIf="objValueValidate(1,'email')"><div class="card-col-fiel card-fiel">{{txtCliente.objValue.email}}</div></div>
                                  <div class="card-row" *ngIf="objValueValidate(1,'direccion')"><div class="card-col-fiel card-fiel">{{txtCliente.objValue.direccionFiscal}}</div></div>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div class="bs-row" [style.paddingTop.px]="15">
                    <div class="bs-col">
                      <mat-form-field class="example-full-width" appearance="legacy">
                        <mat-label>Contacto</mat-label>
                        <input app-input-autocomplete #txtClienteContacto [display]="['nombreCompleto']" type="text" placeholder="Nombre" aria-label="Contacto" matInput formControlName="contactoNombreCompleto" required (keyup)="txtClienteContactos_keyUpEvent($event)" [matAutocomplete]="autoSolicitudClienteContacto" >
                        <mat-icon matSuffix *ngIf="!txtClienteContacto.loading" >{{txtClienteContacto.getIcon()}}</mat-icon>
                        <mat-spinner matSuffix *ngIf="txtClienteContacto.loading" style="margin:0 auto" [diameter]="18" ></mat-spinner>
                        <mat-autocomplete autoActiveFirstOption="false" #autoSolicitudClienteContacto="matAutocomplete" (optionSelected)="getClienteContactoSelected_selectedEvent($event.option.value)">
                          <mat-option *ngFor="let itemClienteContacto of lstClientesContactosFiltered | async" [value]="itemClienteContacto.nombreCompleto">
                            <!--                            <img class="example-option-img" [src]="objApplicationConfig.ENDPOINT + 'api/usuarios/foto/' + itemClienteContacto.idUsuario">-->
                            <span class="title-option">{{itemClienteContacto.nombreCompleto}}</span>
                            <span class="subtitle-option" >{{itemClienteContacto.email}}</span>
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="bs-row" *ngIf="txtClienteContacto.objValue != null">
                      <div class="content-card" style="padding: 0 0 0 5px">
                          <div class="card-row">
                              <div class="card-col cotizacion-card-info">
                                  <div class="card-row"><mat-icon class="info-icon">person</mat-icon><div class="card-col-label card-label">Contacto</div></div>
                                  <div class="card-row" *ngIf="objValueValidate(2,'puesto')"><mat-icon class="info-icon" >work</mat-icon><div class="card-col-label card-label">Puesto</div></div>
                                  <div class="card-row" *ngIf="objValueValidate(2,'telefono')"><mat-icon class="info-icon">phone</mat-icon><div class="card-col-label card-label">Teléfono</div></div>
                                  <div class="card-row" *ngIf="objValueValidate(2,'email')"><mat-icon class="info-icon">email</mat-icon><div class="card-col-label card-label">Correo</div></div>
                              </div>
                              <div class="card-col">
                                  <div class="card-row"><div class="card-col-fiel card-fiel">{{txtClienteContacto.objValue?.nombreCompleto}}</div></div>
                                  <div class="card-row" *ngIf="objValueValidate(2,'puesto')"><div class="card-col-fiel card-fiel">{{txtClienteContacto.objValue.puesto}}</div></div>
                                  <div class="card-row" *ngIf="objValueValidate(2,'telefono')"><div class="card-col-fiel card-fiel">{{txtClienteContacto.objValue.telefono}}</div></div>
                                  <div class="card-row" *ngIf="objValueValidate(2,'email')"><div class="card-col-fiel card-fiel">{{txtClienteContacto.objValue.email}}</div></div>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!--   FIN SOLICITUD   -->

      <!--   INICIO REQUERIMIENTOS   -->
      <div class="datatable-responsive layout" [hidden]="objScene[0] != 'REQUERIMIENTOS'">
        <div class="bs-content">
          <form [formGroup]="fgCotizacionesUpdateRequerimiento">
            <div class="bs-row">
              <div class="bs-col input-comentario">
                <mat-form-field>
                  <textarea matInput formControlName="descripcion" maxlength="4095" placeholder="Descripción del servicio" rows="10"></textarea>
                </mat-form-field>
              </div>
            </div>
            <div class="bs-row">
              <div class="bs-col input-comentario">
                <mat-form-field>
                  <textarea matInput formControlName="alcance" maxlength="4095" placeholder="Alcance del servicio" rows="10"></textarea>
                </mat-form-field>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!--   FIN REQUERIMIENTOS   -->

      <!--   INICIO PROPUESTA   -->
      <div class="datatable-responsive layout" [style.height.%]="100" [hidden]="objScene[0] != 'PROPUESTA'">
          <div class="content-propuesta">
              <div style="flex: 0 0 auto;">
                <form [formGroup]="fgCotizacionesUpdatePropuesta">
                  <div class="cotizacion-input-bar">
                    <div class="option" [style.width.px]="130">
                      <div class="input-label" [class.required]="fgCotizacionesUpdatePropuesta.controls['moneda'].hasError('required')  && fgCotizacionesUpdatePropuesta.controls['moneda'].touched">Monada *</div>
                      <div class="input-field">
                        <select class="table-select" formControlName="moneda" [class.required]="fgCotizacionesUpdatePropuesta.controls['moneda'].hasError('required')  && fgCotizacionesUpdatePropuesta.controls['moneda'].touched" (change)="selectMoneda_selectedEvent($event)">
                          <option *ngFor="let itemMoneda of lstMonedas" [value]="itemMoneda.idMoneda">{{itemMoneda.codigo}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="option">
                      <div class="input-label">Equivalencia</div>
                      <div class="input-field">
                        <span class="suffix">$</span>
                        <input class="table-input text-right" formControlName="equivalencia" type="text" mask="separator.2" thousandSeparator="," matInput maxlength="512" placeholder="MXN - USD" autocomplete="off" [class.required]="fgCotizacionesUpdatePropuesta.controls['equivalencia'].hasError('required')  && fgCotizacionesUpdatePropuesta.controls['equivalencia'].touched">
                      </div>
                    </div>
                    <div class="option" [style.width.px]="210">
                      <div class="input-label" [class.required]="fgCotizacionesUpdatePropuesta.controls['formaPago'].hasError('required')  && fgCotizacionesUpdatePropuesta.controls['formaPago'].touched">Forma de pago *</div>
                      <div class="input-field">
                        <select class="table-select" formControlName="formaPago" [class.required]="fgCotizacionesUpdatePropuesta.controls['formaPago'].hasError('required')  && fgCotizacionesUpdatePropuesta.controls['formaPago'].touched">
                          <option *ngFor="let itemFormaPago of lstFormasPago" [value]="itemFormaPago.idFormaPago">{{itemFormaPago.formaPago}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div style="flex: 0 0 auto;">
                  <div class="options-bar">

                      <div class="option">
                          <button type="button" matTooltip="Nuevo" [disabled]="btnOptionBar.disabled" (click)="btnPartidaAppend_clickEvent()">
                              <span class="material-icons">add</span>
                          </button>
                      </div>
                      <div class="option">
                          <button type="button" matTooltip="Eliminar" [disabled]="!boolChkSomeCotizacionPartida || btnOptionBar.disabled" (click)="btnPartidaDelete_clickEvent()">
                              <span class="material-icons">clear</span>
                          </button>
                      </div>

                  </div>
              </div>
              <div [class]="(lstImpuestos.length < 4)?'cotizacion-table-partidas':'cotizacion-table-partidas-extend'" style="flex: 0 0 auto;">
                  <div class="datatable-responsive">
                    <div class="main">
                      <table class="datatable" [style.width.px]="calcTableWidth()">
                        <thead>
                        <tr [style.maxHeight.px]="20">
                          <th [style.width.px]="40" class="text-center"><mat-checkbox [disabled]="objChkBox.disabled" [checked]="boolChkCotizacionPartida" (change)="chkCotizacionesPartidaSelected_changeEvent($event.checked)" ></mat-checkbox></th>
                          <th [style.width.px]="(valueConfiguracion)?70:35">Acc.</th>
                          <th [style.width.px]="90">Servicio</th>
                          <th>Descripción</th>
                          <th [style.width.px]="60">Cantidad</th>
                          <th [style.width.px]="85">Unidad</th>
                          <th [style.width.px]="100">Precio Unitario</th>
                          <th [style.width.px]="65">Moneda</th>
                          <th [style.width.px]="110">Descuento Cantidad</th>
                          <th [style.width.px]="90">Descuento Porcentaje</th>
                          <th [style.width.px]="30 * lstImpuestos.length">Impuestos</th>
                          <th [style.width.px]="120">Importe</th>
                        </tr>
                        </thead>
                        <tbody *ngIf="valueConfiguracion">
                          <tr *ngFor="let itemCotizacionPartida of lstCotizacionesPartidas; let index = index;">
                            <td class="text-center">
                              <mat-checkbox [disabled]="objChkBox.disabled" [(ngModel)]="itemCotizacionPartida.checked" (ngModelChange)="chkItemCotizacionesPartidaSelected_changeEvent($event)" ></mat-checkbox>
                            </td>
                            <td class="text-center">
                              <button class="datatable-button" [disabled]="btnAccion.disabled">
                                <mat-icon matTooltip="Eliminar" class="datatable-button-icon" (click)="btnPartidasStaticDelete_onDeleteEvent(index)">clear</mat-icon>
                              </button>

                              <button class="datatable-button" [disabled]="btnAccion.disabled">
                                <mat-icon matTooltip="Modificar" class="datatable-button-icon" (click)="btnPartidaEdit_clickEvent(index)">edit</mat-icon>
                              </button>
                            </td>
                            <td class="text-center">{{itemCotizacionPartida.servicio}}</td>
                            <td>{{itemCotizacionPartida.concepto}}</td>
                            <td class="text-center">{{itemCotizacionPartida.cantidad}}</td>
                            <td class="text-center">{{itemCotizacionPartida.unidadMedida}}</td>
                            <td class="text-right">{{itemCotizacionPartida.precioUnitario | currency}}</td>
                            <td class="text-center">{{itemCotizacionPartida.moneda}}</td>
                            <td class="text-right">{{itemCotizacionPartida.descuentoCantidad | currency}}</td>
                            <td class="text-center">{{itemCotizacionPartida.descuentoPorcentaje}}%</td>
                            <td>
                              <div class="bs-content" style="padding: 0 5px;">
                                <div class="bs-row">
                                  <div class="bs-col" style="height: 28px; padding-left: 2px; padding-right: 2px;" *ngFor="let itemImpuesto of itemCotizacionPartida.impuestos">
                                    <span *ngIf="itemImpuesto.checked" class="material-icons checkbox-icon checked" matTooltip="{{itemImpuesto.impuesto}}: {{itemImpuesto.total | currency}} {{itemCotizacionPartida.moneda}}" >check_box</span>
                                    <span *ngIf="!itemImpuesto.checked" class="material-icons checkbox-icon" matTooltip="{{itemImpuesto.impuesto}}: {{itemImpuesto.total | currency}} {{itemCotizacionPartida.moneda}}" >check_box_outline_blank</span>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td class="text-right" [style.paddingRight.px]="15">{{itemCotizacionPartida.importe | currency}} {{objMoneda?.codigo}}</td>
                          </tr>
                        </tbody>
                        <tbody *ngIf="!valueConfiguracion">
                          <tr app-cotizaciones-edit-partida-item
                              #compPartidaItem
                              *ngFor="let itemCotizacionPartida of lstCotizacionesPartidas; let index = index;"
                              [index]                 = "index"
                              [itemCotizacionPartida] = "itemCotizacionPartida"
                              [lstMonedas]            = "lstMonedas"
                              [lstServicios]          = "lstServicios"
                              [lstUnidadesMedidas]    = "lstUnidadesMedidas"
                              [lstImpuestos]          = "lstImpuestos"
                              [equivalencia]          = "equivalencia"
                              [objMoneda]             = "objMoneda"
                              (onDelete)              = "btnPartidasDelete_onDeleteEvent($event)"
                              (onChecked)             = "chkItemCotizacionesPartidaSelected_changeEvent($event)"
                              (onChange)              = "compCotizacionPartidaItem_changeEvent()"
                          ></tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
              </div>
              <div class="space-propuesta"></div>
              <div style="flex: 0 0 auto;">
                  <div class="bs-content" [style.height.px]="(lstImpuestos.length < 4)?81:121" [style.padding.px]="0">
                      <div class="bs-row totales-propuesta">
                          <div class="bs-col-4 cotizacion-partida-col"><span class="totales-label">Importe total:</span><span class="totales-field">{{importe | currency}} {{objMoneda?.codigo}}</span></div>
                          <div class="bs-col-4 cotizacion-partida-col"><span class="totales-label">Descuento:</span><span class="totales-field">{{descuento| currency}} {{objMoneda?.codigo}}</span></div>
                          <div class="bs-col-4 cotizacion-partida-col"><span class="totales-label">Subtotal:</span><span class="totales-field-total">{{subtotal | currency}} {{objMoneda?.codigo}}</span></div>
                      </div>
                      <div class="bs-row" [class]="(lstImpuestos.length < 4)?'totales-propuesta':'totales-propuesta-extend'">
                          <div class="bs-col" [style.padding.px]="0">
                              <div class="bs-row">
                                  <div class="bs-col cotizacion-partida-col-dynamic" [style.minWidth.%]="33.3333333333" *ngFor="let itemImpuesto of lstImpuestos;">
                                      <span class="material-icons" *ngIf="itemImpuesto.impuestoTipo.idImpuestoTipo == 1" matTooltip="Se suma al total">add_circle_outline</span>
                                      <span class="material-icons" *ngIf="itemImpuesto.impuestoTipo.idImpuestoTipo != 1" matTooltip="Se resta al total">remove_circle_outline</span>
                                      <span class="totales-label">{{itemImpuesto.impuesto}}:</span>
                                      <span class="totales-field">{{itemImpuesto?.total | currency}} {{objMoneda?.codigo}}</span>
                                  </div>
                              </div>
                          </div>
                          <div class="bs-col-4" [class]="(lstImpuestos.length < 4)?'cotizacion-partida-col-dynamic':'cotizacion-partida-col-dynamic-extend'" [style.maxWidth.%]="33.3333333333"><span class="totales-label">Total:</span><span class="totales-field-total">{{total | currency}} {{objMoneda?.codigo}}</span></div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <!--   FIN PROPUESTA   -->

      <!--   INICIO COMENTARIOS   -->
      <div class="datatable-responsive layout" [hidden]="objScene[0] != 'COMENTARIOS'">
        <div class="flex-container">
          <div class="content">
            <div class="options-bar">
              <div class="input-option" [style.width.px]="400">
                <div class="label" >Tipo de cotización</div>
                <div class="field">
                  <input class="table-input" app-input-autocomplete #txtCotizacionTipo [formControl]="fcCotizacionTipo" [display]="['cotizacionTipo']" type="text" placeholder="Tipo de cotización" matInput (keyup)="txtCotizacionTipo_keyUpEvent($event)" [matAutocomplete]="autoCotizacionTipo" (focusout)="txtCotizacionTipo_OnFocusOutEvent()">
                  <!--              <mat-icon matSuffix *ngIf="!txtCotizacionTipo.loading" >{{txtCotizacionTipo.getIcon()}}</mat-icon>-->
                  <!--              <mat-spinner matSuffix *ngIf="txtCotizacionTipo.loading" style="margin:0 auto" [diameter]="18" ></mat-spinner>-->
                  <mat-autocomplete autoActiveFirstOption="false" #autoCotizacionTipo="matAutocomplete" (optionSelected)="getCotizacionTipo_selectedEvent($event.option.value)" >
                    <mat-option class="cotizacion-condicion" *ngFor="let itemCotizacionTipo of lstCotizacionesTipos" [value]="itemCotizacionTipo.idCotizacionTipo">
                      <span>{{itemCotizacionTipo.cotizacionTipo}}</span>
                    </mat-option>
                  </mat-autocomplete>
                </div>
              </div>

              <div class="option">
                <button type="button" matTooltip="Nuevo" (click)="btnAddCotizacionesCondiciones_clickEvent()">
                  <span class="material-icons">add</span>
                </button>
              </div>

              <div class="option">
                <button type="button" matTooltip="Eliminar" (click)="btnDeleteCotizacionesCondiciones_clickEvent()">
                  <span class="material-icons">clear</span>
                </button>
              </div>
            </div>

            <div class="input-condiciones-content">
              <div class="item-input-condiciones" *ngFor="let itemCotizacionCondicion of lstCotizacionesCondicionesSorted; let index = index;">
                <div class="accions" style="min-width: 68px">
                  <mat-checkbox [(ngModel)]="itemCotizacionCondicion.checked"></mat-checkbox>

                  <button class="datatable-button" [disabled]="btnAceptar.disabled" *ngIf="itemCotizacionCondicion?.idCotizacionCondicion < 1">
                    <mat-icon matTooltip="Eliminar" class="datatable-button-icon" (click)="btnItemDeleteCotizacionesCondiciones_clickEvent(index)">clear</mat-icon>
                  </button>

                  <button class="datatable-button" [disabled]="btnAceptar.disabled" *ngIf="itemCotizacionCondicion?.undo">
                    <mat-icon matTooltip="Regresar a la condición anterior" class="datatable-button-icon" (click)="btnItemUndoCotizacionesCondiciones_clickEvent(index)">undo</mat-icon>
                  </button>

                  <button class="datatable-button" [disabled]="btnAceptar.disabled" *ngIf="itemCotizacionCondicion?.redo">
                    <mat-icon matTooltip="Regresar a la condición actual" class="datatable-button-icon" (click)="btnItemRedoCotizacionesCondiciones_clickEvent(index)">redo</mat-icon>
                  </button>
                </div>
                <div class="input-condiciones">
                  <textarea class="table-textarea" #textareaCondicion [style.width.%]="100" [(ngModel)]="itemCotizacionCondicion.cotizacionCondicion" maxlength="1024" (keyup)="textareat($event, index)"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--   FIN COMENTARIOS   -->

      <!--   INICIO DOCUMENTACIÓN   -->
      <div class="datatable-responsive layout" [hidden]="objScene[0] != 'DOCUMENTACION'">
        <div class="bs-content">
          <div class="bs-row">
            <div class="bs-col">
              <div [style.width.px]="240">
                <comp-zone-uploader [hidden]="objLoader.visible" #compZoneUploader [height]="40" [config]="confDragDropFile"></comp-zone-uploader>
              </div>
            </div>
          </div>
        </div>

        <div class="datatable-responsive layout" *ngIf="compZoneUploader.files.length > 0">

          <table class="datatable">
            <thead>
            <tr>
              <th [style.width.px]="30" class="text-center"></th>
              <th [style.width.px]="50">Acc.</th>
              <th>Nombre</th>
              <th [style.width.%]="50"></th>
            </tr>
            </thead>

            <tbody>
            <tr app-cotizaciones-edit-file-item
                #compFileItem
                *ngFor="let itemFile of compZoneUploader.files;
            let index = index;"
                [itemFile] = "itemFile"
                [index]    = "index"
                (onDelete) = "compCotizacionFicheroFileItem_deleteEvent($event)"
                (onChange) = "compCotizacionFicheroFileItem_changeEvent($event)"></tr>
            </tbody>
          </table>
        </div>

        <div class="datatable-responsive layout">

          <div class="options-bar">

            <div class="option">
              <button type="button" [disabled]="!boolChkSomeCotizacionFichero || btnOptionBar.disabled" (click)="btnCotizacionFicheroEliminarSeleccionados_clickEvent()" matTooltip="Eliminar seleccionados">
                <span class="material-icons">clear</span>
<!--                <div class="option-label">eliminar</div>-->
              </button>
            </div>

            <!-- <div class="spacer"></div> -->

          </div>

          <table class="datatable">
            <thead>
            <tr>
              <th [style.width.px]="40" class="text-center"><mat-checkbox [disabled]="objChkBox.disabled" [checked]="boolChkCotizacionFichero" (change)="chkCotizacionesFicheroSelected_changeEvent($event.checked)"></mat-checkbox></th>
              <th [style.width.px]="60">No.</th>
              <th [style.width.px]="100">Acciones</th>
              <th>Nombre</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let itemClienteFichero of lstCotizacionesFicheros; let index = index;">
              <td class="text-center"><mat-checkbox [disabled]="objChkBox.disabled" [(ngModel)]="itemClienteFichero.checked" (ngModelChange)="chkItemCotizacionesFicheroSelected_changeEvent($event.checked)"></mat-checkbox></td>
              <td class="text-center">{{(index + 1)}}</td>

              <td class="text-center">
                <button class="datatable-button" [disabled]="btnAccion.disabled" matTooltip="Eliminar">
                  <mat-icon class="datatable-button-icon" (click)="btnCotizacionFicheroDelete_clickEvent(itemClienteFichero, index)">clear</mat-icon>
                </button>
              </td>

              <td>{{itemClienteFichero.nombre}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--    FIN DOCUMENTACIÓN   -->
    </div>
  </div>
</div>

<div class="mat-dialog-loader">
  <mat-progress-bar class="loader" *ngIf="objLoader.visible" color="accent" mode="indeterminate"></mat-progress-bar>
</div>

<div class="mat-dialog-actions">
  <button class="primary" type="button" [disabled]="btnAceptar.disabled" (click)="formCotizacionesUpdate_submitEvent()">Aceptar</button>
  <button class="gray-color" type="button" [disabled]="btnCancelar.disabled" (click)="close()">Cancelar</button>
</div>
