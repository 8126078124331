<div class="mat-dialog-title">
    <div class="title"> {{folio}}Finalizar orden de servicio</div>
    <div class="spacer"></div>
    <div class="buttons">
        <button type="button" class="title-button" (click)="close()">
            <mat-icon class="title-button-icon">clear</mat-icon>
        </button>
    </div>
</div>

<div class="mat-dialog-body">
    <div class="bs-content-full-area" [style.height.%]="100">
        <div class="bs-sidebar-left" [style.width.px]="250">
            <div class="bs-content">
                <div class="row">
                    <div class="col text-center">
                        <span class="material-icons" [style.fontSize.px]="80">description</span>
                    </div>
                </div>
                <div class="bs-row">
                    <div class="bs-col">
                        <mat-selection-list [(ngModel)]="objScene" [multiple]="false">
                            <mat-list-option [value]="'CLIENTE'">Cliente</mat-list-option>
                            <mat-list-option [value]="'OPERACION'">Operación</mat-list-option>
                        </mat-selection-list>
                    </div>
                </div>
            </div>
        </div>
        <div class="bs-viewport">
            <!--   INICIO SOLICITUD   -->
            <div class="datatable-responsive layout" [hidden]="objScene[0] != 'CLIENTE'">
                <div class="bs-content">
                    <div fxLayout="row wrap">
                        <!-- Card column -->
                        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                            <mat-card class="panel-content-card">
                                <div class="content-card" style="padding: 20px; ">
                                    <div class="card-row">
                                        <div class="card-col">
                                            <div class="img-foto" [style.background]="'url(' + objApplicationConfig.ENDPOINT + 'api/empresas/logotipo/' + objOrdenPedido.idEmpresa + '/512)'"></div></div>
                                        <div class="card-col" [style.paddingLeft.px]="30">
                                            <div class="card-row">
                                                <div class="card-col-fiel card-fiel" style="font-size: 18px;font-weight: 500;">{{objOrdenPedido.empresaNombreComercial}}</div>
                                            </div>
                                            <div class="card-row">
                                                <div class="card-col-fiel card-fiel">{{objOrdenPedido.empresaRazonSocial}}</div>
                                            </div>
                                            <div class="card-row" [style.paddingTop.px]="10">
                                                <div class="card-col-fiel card-fiel">{{objOrdenPedido.empresaEmail}}</div>
                                            </div>
                                            <div class="card-row">
                                                <div class="card-col-fiel card-fiel">{{objOrdenPedido.empresaTelefono}}</div>
                                            </div>
                                            <div class="card-row">
                                                <div class="card-col-fiel card-fiel">{{objOrdenPedido.empresaDireccionFiscal}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-card>
                        </div>
                    </div>

                    <div fxLayout="row wrap">
                        <!-- Card column -->
                        <div fxFlex.gt-lg="100" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
                            <mat-card class="panel-content-card">
                                <div class="content-card">
                                    <div class="card-row br_btm">
                                        <div class="card-col-title">Cliente</div>
                                    </div>
                                    <div class="card-row">
                                        <div class="card-col">
                                            <div class="card-row" style="margin: 5px 20px 0 20px">
                                                <div class="card-col-label card-label">Nombre comercial</div>
                                            </div>
                                            <div class="card-row" style="margin: 0 20px 0 20px">
                                                <div class="card-col-label card-label">Razón social</div>
                                            </div>
                                            <div class="card-row" style="margin: 0 20px 0 20px">
                                                <div class="card-col-label card-label">R.F.F</div>
                                            </div>
                                            <div class="card-row" style="margin: 0 20px 0 20px">
                                                <div class="card-col-label card-label">Dirección fiscal</div>
                                            </div>
                                            <div class="card-row" style="margin: 0 20px; padding-bottom: 15px">
                                                <div class="card-col-label card-label">Dirección social</div>
                                            </div>
                                        </div>
                                        <div class="card-col">
                                            <div class="card-row" style="margin: 5px 20px 0 20px">
                                                <div class="card-col-fiel card-fiel">{{objOrdenPedido.clienteNombreComercial}}</div>
                                            </div>
                                            <div class="card-row" style="margin: 0 20px 0 20px">
                                                <div class="card-col-fiel card-fiel">{{objOrdenPedido.clienteRazonSocial}}</div>
                                            </div>
                                            <div class="card-row" style="margin: 0 20px 0 20px">
                                                <div class="card-col-fiel card-fiel">{{objOrdenPedido.clienteRfc}}</div>
                                            </div>
                                            <div class="card-row" style="margin: 0 20px 0 20px">
                                                <div class="card-col-fiel card-fiel">{{objOrdenPedido.clienteDireccionFiscal}}</div>
                                            </div>
                                            <div class="card-row" style="margin: 0 20px; padding-bottom: 15px">
                                                <div class="card-col-fiel card-fiel">{{objOrdenPedido.clienteDireccionSocial}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-card>
                        </div>

                        <div fxFlex.gt-lg="100" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
                            <mat-card class="panel-content-card">
                                <div class="content-card">
                                    <div class="card-row br_btm">
                                        <div class="card-col-title">Contacto</div>
                                    </div>
                                    <div class="card-row">
                                        <div class="card-col">
                                            <div class="card-row" style="margin: 5px 20px 0 20px">
                                                <div class="card-col-label card-label">Nombre</div>
                                            </div>
                                            <div class="card-row" style="margin: 0 20px 0 20px">
                                                <div class="card-col-label card-label">Puesto</div>
                                            </div>
                                            <div class="card-row" style="margin: 0 20px 0 20px">
                                                <div class="card-col-label card-label">Teléfono</div>
                                            </div>
                                            <div class="card-row" style="margin: 0 20px; padding-bottom: 15px">
                                                <div class="card-col-label card-label">Correo</div>
                                            </div>
                                        </div>
                                        <div class="card-col">
                                            <div class="card-row" style="margin: 5px 20px 0 20px">
                                                <div class="card-col-fiel card-fiel">{{objOrdenPedido.contactoNombreCompleto}}</div>
                                            </div>
                                            <div class="card-row" style="margin: 0 20px 0 20px">
                                                <div class="card-col-fiel card-fiel">{{objOrdenPedido.contactoPuesto}}</div>
                                            </div>
                                            <div class="card-row" style="margin: 0 20px 0 20px">
                                                <div class="card-col-fiel card-fiel">{{objOrdenPedido.contactoTelefono}}</div>
                                            </div>
                                            <div class="card-row" style="margin: 0 20px; padding-bottom: 15px">
                                                <div class="card-col-fiel card-fiel">{{objOrdenPedido.contactoEmail}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-card>
                        </div>
                    </div>

                </div>
            </div>
            <!--   FIN SOLICITUD   -->

            <!--   INICIO COMENTARIOS   -->
            <div class="datatable-responsive layout" [hidden]="objScene[0] != 'OPERACION'">
                <div class="options-bar">
                    <div class="bs-content">
                        <div class="bs-row">
                            <div class="bs-col">Tiempos de ejecución </div>
                        </div>
                    </div>
                </div>
                <div class="main">
                  <form [formGroup]="fgOrdenServicioTiemposEjecucion">
                    <table class="datatable" style="min-width: 50%">
                      <thead>
                      <tr>
                        <th [style.width.px]="135"></th>
                        <th [style.width.px]="130">Fecha</th>
                        <th [style.width.px]="110">Hora</th>
                        <th [style.width.px]="140">Odometro</th>
                        <th [style.width.px]="140">Horometro</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>Fecha programada</td>
                        <td>
                          <div class="table-input-calendar">
                            <input class="text-center" formControlName="programadaFecha" [matDatepicker]="matDateProgramada">
                            <mat-datepicker #matDateProgramada></mat-datepicker>
                            <button disabled><span class="material-icons">today</span></button>
                          </div>
                        </td>
                        <td>
                          <input type="text" class="table-input text-center timeInput" formControlName="programadaHora">
                          <span class="suffixSpan">Hrs.</span>
                        </td>
                        <td class="text-center">---</td>
                        <td class="text-center">---</td>
                      </tr>
                      <tr>
                        <td>Salida del origen</td>
                        <td>
                          <div class="table-input-calendar">
                            <input class="text-center" formControlName="salidaOrigenFecha" [matDatepicker]="matDateFin">
                            <mat-datepicker #matDateFin></mat-datepicker>
                            <button disabled><span class="material-icons">today</span></button>
                          </div>
                        </td>
                        <td>
                          <input type="text" class="table-input text-center timeInput" formControlName="salidaOrigenHora">
                          <span class="suffixSpan">Hrs.</span>
                        </td>
                        <td>
                          <div class="table-input text-right claseInput" [hidden]="!boolOdometro">{{ fgOrdenServicioTiemposEjecucion.controls['salidaOrigenKms'].value | number: '1.'}} <span class="suffixSpan" >Kms.</span></div>
                          <div class="text-center" [hidden]="boolOdometro">---</div>
                        </td>
                        <td>
                          <div class="table-input text-center claseInput" [hidden]="!boolHorometro">{{ fgOrdenServicioTiemposEjecucion.controls['salidaOrigenHrs'].value | number: '1.'}} <span class="suffixSpan" >Hrs.</span></div>
                          <div class="text-center" [hidden]="boolHorometro">---</div>
                        </td>
                      </tr>
                      <tr>
                        <td [class.required]="fgOrdenServicioTiemposEjecucion.controls['llegadaDestinoFecha'].hasError('required')  && fgOrdenServicioTiemposEjecucion.controls['llegadaDestinoFecha'].touched">Llegada al destino *</td>
                        <td>
                          <div class="table-input-calendar" [class.required]="fgOrdenServicioTiemposEjecucion.controls['llegadaDestinoFecha'].hasError('required')  && fgOrdenServicioTiemposEjecucion.controls['llegadaDestinoFecha'].touched">
                            <input class="text-center" matInput formControlName="llegadaDestinoFecha" [matDatepicker]="matDateLlegadaDestino"
                                   [readonly]="true" (click)="matDateLlegadaDestino.open()">
                            <mat-datepicker #matDateLlegadaDestino></mat-datepicker>
                            <button mat-raised-button (click)="matDateLlegadaDestino.open()"><span class="material-icons">today</span></button>
                          </div>
                        </td>
                        <td>
                          <input type="text"
                                 class="table-input text-center timeInput"
                                 mask="Hh:m0"
                                 matInput
                                 maxlength="5"
                                 autocomplete="off"
                                 formControlName="llegadaDestinoHora"
                                 [class.required]="fgOrdenServicioTiemposEjecucion.controls['llegadaDestinoHora'].hasError('required')  && fgOrdenServicioTiemposEjecucion.controls['llegadaDestinoHora'].touched"
                                 (keyup)="inputLlegadaDestinoTime_keyUpEvent($event)">
                          <span class="suffixSpan" [class.required]="fgOrdenServicioTiemposEjecucion.controls['llegadaDestinoHora'].hasError('required')  && fgOrdenServicioTiemposEjecucion.controls['llegadaDestinoHora'].touched">Hrs. *</span>
                        </td>
                        <td>
                          <div class="table-input text-right claseInput" [hidden]="!boolOdometro">{{ fgOrdenServicioTiemposEjecucion.controls['llegadaDestinoKms'].value | number: '1.'}} <span class="suffixSpan" >Kms.</span></div>
                          <div class="text-center" [hidden]="boolOdometro">---</div>
                        </td>
                        <td>
                          <div class="table-input text-center claseInput" [hidden]="!boolHorometro">{{ fgOrdenServicioTiemposEjecucion.controls['llegadaDestinoHrs'].value | number: '1.'}} <span class="suffixSpan" >Hrs.</span></div>
                          <div class="text-center" [hidden]="boolHorometro">---</div>
                        </td>
                      </tr>
                      <tr>
                        <td [class.required]="fgOrdenServicioTiemposEjecucion.controls['salidaDestinoFecha'].hasError('required')  && fgOrdenServicioTiemposEjecucion.controls['salidaDestinoFecha'].touched">Salida del destino *</td>
                        <td>
                          <div class="table-input-calendar" [class.required]="fgOrdenServicioTiemposEjecucion.controls['salidaDestinoFecha'].hasError('required')  && fgOrdenServicioTiemposEjecucion.controls['salidaDestinoFecha'].touched">
                            <input class="text-center" matInput formControlName="salidaDestinoFecha" [matDatepicker]="matDateSalidaDestino"
                                   [readonly]="true" (click)="matDateSalidaDestino.open()">
                            <mat-datepicker #matDateSalidaDestino></mat-datepicker>
                            <button mat-raised-button (click)="matDateSalidaDestino.open()"><span class="material-icons">today</span></button>
                          </div>
                        </td>
                        <td>
                          <input type="text"
                                 class="table-input text-center timeInput"
                                 mask="Hh:m0"
                                 matInput
                                 maxlength="5"
                                 autocomplete="off"
                                 formControlName="salidaDestinoHora"
                                 [class.required]="fgOrdenServicioTiemposEjecucion.controls['salidaDestinoHora'].hasError('required')  && fgOrdenServicioTiemposEjecucion.controls['salidaDestinoHora'].touched"
                                 (keyup)="inputSalidaDestinoTime_keyUpEvent($event)">
                          <span class="suffixSpan" [class.required]="fgOrdenServicioTiemposEjecucion.controls['salidaDestinoHora'].hasError('required')  && fgOrdenServicioTiemposEjecucion.controls['salidaDestinoHora'].touched">Hrs. *</span>
                        </td>
                        <td>
                          <div class="table-input text-right claseInput" [hidden]="!boolOdometro">{{ fgOrdenServicioTiemposEjecucion.controls['salidaDestinoKms'].value | number: '1.'}} <span class="suffixSpan" >Kms.</span></div>
                          <div class="text-center" [hidden]="boolOdometro">---</div>
                        </td>
                        <td>
                          <div class="table-input text-center claseInput" [hidden]="!boolHorometro">{{ fgOrdenServicioTiemposEjecucion.controls['salidaDestinoHrs'].value | number: '1.'}} <span class="suffixSpan" >Hrs.</span></div>
                          <div class="text-center" [hidden]="boolHorometro">---</div>
                        </td>
                      </tr>
                      <tr>
                        <td [class.required]="fgOrdenServicioTiemposEjecucion.controls['llegadaOrigenFecha'].hasError('required')  && fgOrdenServicioTiemposEjecucion.controls['llegadaOrigenFecha'].touched">Llegada al origen *</td>
                        <td>
                          <div class="table-input-calendar" [class.required]="fgOrdenServicioTiemposEjecucion.controls['llegadaOrigenFecha'].hasError('required')  && fgOrdenServicioTiemposEjecucion.controls['llegadaOrigenFecha'].touched">
                            <input class="text-center" matInput formControlName="llegadaOrigenFecha" [matDatepicker]="matDateLlegadaOrigen"
                                   [readonly]="true" (click)="matDateLlegadaOrigen.open()">
                            <mat-datepicker #matDateLlegadaOrigen></mat-datepicker>
                            <button mat-raised-button (click)="matDateLlegadaOrigen.open()"><span class="material-icons">today</span></button>
                          </div>
                        </td>
                        <td>
                          <input type="text"
                                 class="table-input text-center timeInput"
                                 mask="Hh:m0"
                                 matInput
                                 maxlength="5"
                                 autocomplete="off"
                                 formControlName="llegadaOrigenHora"
                                 [class.required]="fgOrdenServicioTiemposEjecucion.controls['llegadaOrigenHora'].hasError('required')  && fgOrdenServicioTiemposEjecucion.controls['llegadaOrigenHora'].touched"
                                 (keyup)="inputLlegadaOrigenTime_keyUpEvent($event)">
                          <span class="suffixSpan" [class.required]="fgOrdenServicioTiemposEjecucion.controls['llegadaOrigenHora'].hasError('required')  && fgOrdenServicioTiemposEjecucion.controls['llegadaOrigenHora'].touched">Hrs. *</span>
                        </td>
                        <td>
                          <div class="table-input text-right claseInput" [hidden]="!boolOdometro">{{ fgOrdenServicioTiemposEjecucion.controls['llegadaOrigenKms'].value | number: '1.'}} <span class="suffixSpan" >Kms.</span></div>
                          <div class="text-center" [hidden]="boolOdometro">---</div>
                        </td>
                        <td>
                          <div class="table-input text-center claseInput" [hidden]="!boolHorometro">{{ fgOrdenServicioTiemposEjecucion.controls['llegadaOrigenHrs'].value | number: '1.'}} <span class="suffixSpan" >Hrs.</span></div>
                          <div class="text-center" [hidden]="boolHorometro">---</div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </form>
                </div>

              <div>
                <table class="datatable" style="min-width: 50%">
                  <thead>
                  <tr>
                    <th [style.width.px]="375">Unidad</th>
                    <th [style.width.px]="140">Odómetro</th>
                    <th [style.width.px]="140">Horómetro</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let itemUnidadMiembro of lstUnidadesMiembros; let index = index;">
                    <td>
                      <div class="card-info-content">
                        <div class="card-body">
                          <div class="card-text">
                            <div class="card-label">No. Economico</div>
                            <div class="card-field"><div>{{itemUnidadMiembro.numeroEconomico}}</div> <div class="material-icons" *ngIf="itemUnidadMiembro.isPrincipal" matTooltip="Unidad principal" >verified</div></div>
                          </div>
                          <div class="card-text">
                            <div class="card-label">Tipo de unidad</div>
                            <div class="card-field">{{itemUnidadMiembro.unidadTipo}}</div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="card-info-content">
                        <div class="card-body">
                          <div class="card-text card-input-content">
                            <div class="card-input" *ngIf="Globals.exist(itemUnidadMiembro?.salidaOrigenOdometro)">
                              <div class="table-input unidad text-right iconPrefix">{{ itemUnidadMiembro.salidaOrigenOdometro | number: '1.' }}</div>
                              <span class="suffix unidad">Kms.</span>
                            </div>
                            <div class="card-input text-center" *ngIf="!Globals.exist(itemUnidadMiembro?.salidaOrigenOdometro)">---</div>
                          </div>
                          <div class="card-text card-input-content">
                            <div class="card-input" *ngIf="Globals.exist(itemUnidadMiembro?.salidaOrigenOdometro)">
                              <span class="material-icons prefix" *ngIf="boolValidationLlegadaDestinoOdometro(index)" matTooltip="El valor no puede ser menor">warning</span>
                              <input type="text" class="table-input unidad text-right iconPrefix" [(ngModel)]="itemUnidadMiembro.llegadaDestinoOdometro" mask="separator.2" thousandSeparator="," matInput maxlength="127" autocomplete="off" (keyup)="inputLlegadaDestinoOdometro_changeEvent($event, index)">
                              <span class="suffix unidad">Kms.</span>
                            </div>
                            <div class="card-input text-center" *ngIf="!Globals.exist(itemUnidadMiembro?.salidaOrigenOdometro)">---</div>
                          </div>
                          <div class="card-text card-input-content">
                            <div class="card-input" *ngIf="Globals.exist(itemUnidadMiembro?.salidaOrigenOdometro)">
                              <span class="material-icons prefix" *ngIf="boolValidationSalidaDestinoOdometro(index)" matTooltip="El valor no puede ser menor">warning</span>
                              <input type="text" class="table-input unidad text-right iconPrefix" [(ngModel)]="itemUnidadMiembro.salidaDestinoOdometro" mask="separator.2" thousandSeparator="," matInput maxlength="127" autocomplete="off" (keyup)="inputSalidaDestinoOdometro_changeEvent($event, index)">
                              <span class="suffix unidad">Kms.</span>
                            </div>
                            <div class="card-input text-center" *ngIf="!Globals.exist(itemUnidadMiembro?.salidaOrigenOdometro)">---</div>
                          </div>
                          <div class="card-text card-input-content">
                            <div class="card-input" *ngIf="Globals.exist(itemUnidadMiembro?.salidaOrigenOdometro)">
                              <span class="material-icons prefix" *ngIf="boolValidationLlegadaOrigenOdometro(index)" matTooltip="El valor no puede ser menor">warning</span>
                              <input type="text" class="table-input unidad text-right iconPrefix" [(ngModel)]="itemUnidadMiembro.llegadaOrigenOdometro" mask="separator.2" thousandSeparator="," matInput maxlength="127" autocomplete="off" (keyup)="inputLlegadaOrigenOdometro_changeEvent($event, index)">
                              <span class="suffix unidad">Kms.</span>
                            </div>
                            <div class="card-input text-center" *ngIf="!Globals.exist(itemUnidadMiembro?.salidaOrigenOdometro)">---</div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="card-info-content">
                        <div class="card-body">
                          <div class="card-text card-input-content">
                            <div class="card-input" *ngIf="Globals.exist(itemUnidadMiembro?.salidaOrigenHorometro)">
                              <div class="table-input unidad text-center iconPrefix">{{ itemUnidadMiembro.salidaOrigenHorometro | number: '1.' }}</div>
                              <span class="suffix unidad">Hrs.</span>
                            </div>
                            <div class="card-input text-center" *ngIf="!Globals.exist(itemUnidadMiembro?.salidaOrigenHorometro)">---</div>
                          </div>
                          <div class="card-text card-input-content">
                            <div class="card-input" *ngIf="Globals.exist(itemUnidadMiembro?.salidaOrigenHorometro)">
                              <span class="material-icons prefix" *ngIf="boolValidationLlegadaDestinoHorometro(index)" matTooltip="El valor no puede ser menor">warning</span>
                              <input type="text" class="table-input unidad text-center iconPrefix" [(ngModel)]="itemUnidadMiembro.llegadaDestinoHorometro" mask="separator.2" thousandSeparator="," matInput maxlength="127" autocomplete="off" (keyup)="inputLlegadaDestinoHorometro_changeEvent($event, index)">
                              <span class="suffix unidad">Hrs.</span>
                            </div>
                            <div class="card-input text-center" *ngIf="!Globals.exist(itemUnidadMiembro?.salidaOrigenHorometro)">---</div>
                          </div>
                          <div class="card-text card-input-content">
                            <div class="card-input" *ngIf="Globals.exist(itemUnidadMiembro?.salidaOrigenHorometro)">
                              <span class="material-icons prefix" *ngIf="boolValidationSalidaDestinoHorometro(index)" matTooltip="El valor no puede ser menor">warning</span>
                              <input type="text" class="table-input unidad text-center iconPrefix" [(ngModel)]="itemUnidadMiembro.salidaDestinoHorometro" mask="separator.2" thousandSeparator="," matInput maxlength="127" autocomplete="off" (keyup)="inputSalidaDestinoHorometro_changeEvent($event, index)">
                              <span class="suffix unidad">Hrs.</span>
                            </div>
                            <div class="card-input text-center" *ngIf="!Globals.exist(itemUnidadMiembro?.salidaOrigenHorometro)">---</div>
                          </div>
                          <div class="card-text card-input-content">
                            <div class="card-input" *ngIf="Globals.exist(itemUnidadMiembro?.salidaOrigenHorometro)">
                              <span class="material-icons prefix" *ngIf="boolValidationLlegadaOrigenHorometro(index)" matTooltip="El valor no puede ser menor">warning</span>
                              <input type="text" class="table-input unidad text-center iconPrefix" [(ngModel)]="itemUnidadMiembro.llegadaOrigenHorometro" mask="separator.2" thousandSeparator="," matInput maxlength="127" autocomplete="off" (keyup)="inputLlegadaOrigenHorometro_changeEvent($event, index)">
                              <span class="suffix unidad">Hrs.</span>
                            </div>
                            <div class="card-input text-center" *ngIf="!Globals.exist(itemUnidadMiembro?.salidaOrigenHorometro)">---</div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>

                <div class="bs-content">
                  <div class="bs-row">
                    <div class="bs-col">
                      <form [formGroup]="fgOrdenServicioTiemposEjecucion">
                        <mat-form-field appearance="legacy">
                          <textarea matInput formControlName="observaciones" placeholder="Observaciones" rows="10" cols="70"></textarea>
                        </mat-form-field>
                      </form>
                    </div>
                  </div>
                </div>
            </div>
            <!--   FIN COMENTARIOS   -->

        </div>
    </div>
</div>

<div class="mat-dialog-loader">
    <mat-progress-bar class="loader" *ngIf="objLoader.visible" color="accent" mode="indeterminate"></mat-progress-bar>
</div>

<div class="mat-dialog-actions">
    <button class="primary" type="button" [disabled]="btnAceptar.disabled" (click)="formOrdenServicioCreate_submitEvent()">Aceptar
    </button>
    <button class="gray-color" type="button" [disabled]="btnCancelar.disabled" (click)="close()">Cancelar</button>
</div>

