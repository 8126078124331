import {AfterViewInit, Component, EventEmitter, Inject, OnInit, Output, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {OrdenesPedidosService} from "../../../../../services/ordenes-pedidos.service";
import {FormOptimizer} from "../../../../../libraries/formOptimizer";
import {ApplicationConfig} from "../../../../../libraries/application-config";
import {ContextMenu} from "primeng/contextmenu";
import {MenuItem} from "primeng/api";
import {OrdenesServiciosCreateComponent} from "../create/create.component";
import {Globals} from "../../../../../libraries/globals";
import {FileViewerComponent} from "../../../../../components/file-viewer/file-viewer.component";
import {saveAs} from "file-saver";
import {OrdenesServiciosService} from "../../../../../services/ordenes-servicios.service";
import {OrdenesServiciosFinalizarComponent} from "../finalize/finalize.component";
import {ConfirmComponent} from "../../../../../components/confirm/confirm.component";
import {SuccessComponent} from "../../../../../components/success/success.component";
import {AuthService} from "../../../../../services/auth.service";
import {AlertComponent} from "../../../../../components/alert/alert.component";
import {OrdenPedidoGenerateAgreementComponent} from "../generate-agreement/generate-agreement.component";
import {OrdenesServiciosPaymentComponent} from "../payment/payment.component";

@Component({
  selector: 'app-ordenes-servicio-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class OrdenesServiciosIndexComponent implements OnInit, AfterViewInit {

  @Output('onClose') onClose: EventEmitter<OrdenesServiciosIndexComponent> = new EventEmitter<OrdenesServiciosIndexComponent>();
  @ViewChild('contextMenu') contextMenu : ContextMenu;

  public items: MenuItem[] = [];

  public Globals                  = Globals;
  public objApplicationConfig     = ApplicationConfig;
  public objPrivilegios           : any = {};
  public objOrdenPedido           : any = {}
  public objOrdenPedidoReturn     : any = {}
  public objCotizacion            : any = {}
  public lstCotizacionPartidas    : Array<any> = [];
  public lstOdernesServicios      : Array<any> = [];
  public objScene                 : any = ["CLIENTE"];
  public folio                    : string = '';
  public isFinalize               : boolean = false;
  public boolChkOrdenServicio     : boolean = false;
  public boolChkSomeOrdenServicio : boolean = false;
  public boolChkHasContrato       : boolean = false;

  public objLoader = {
    type            : 'loader',
    visible         : false
  };

  public formComponents: Array<any> = [
    this.objLoader
  ];

  constructor(private objDialogRef: MatDialogRef<OrdenesServiciosIndexComponent>,
              private objOrdenesPedidosService : OrdenesPedidosService,
              private objOrdenesServiciosService : OrdenesServiciosService,
              private objAuthService : AuthService,
              public objMatDialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.objPrivilegios = objAuthService.privilegios;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout( () => this.init());
  }

  public init() {
    if(Globals.exist(this.data.scene)) this.objScene = [this.data.scene];
    if(Globals.exist(this.data.folio)) this.folio = ' - ' + this.data.folio;
    FormOptimizer.formDisable(this.formComponents);
    this.objOrdenesPedidosService.indexOrdenServicio( this.data.idOrdenPedido )
      .subscribe( objResponse=> {
        this.objOrdenPedido = objResponse.result?.ordenPedido
        this.isFinalize     = objResponse.result?.isFinalize;
        this.objCotizacion  = objResponse.result?.cotizacion;
        this.lstCotizacionPartidas = this.objCotizacion?.partidasAprobadas;
        this.lstOdernesServicios = objResponse.result?.ordenesServicios;
        this.lstOdernesServicios.forEach( itemOrdenServicio => {
          let lstUnidades : Array<any> = [];
          let lstOperadores : Array<any> = [];
          itemOrdenServicio?.miembros.forEach( (itemMiembro : any) => {
            if(Globals.exist(itemMiembro?.idUnidad)) {
              let objUnidad : any = {idUnidad: itemMiembro?.idUnidad, numeroEconomico : itemMiembro?.numeroEconomico}
              lstUnidades.push(objUnidad);
            }

            if(Globals.exist(itemMiembro?.operadores) && itemMiembro?.operadores.length > 0) {
              lstOperadores = lstOperadores.concat(itemMiembro?.operadores);
            }
          });
          itemOrdenServicio['unidades'] = lstUnidades;
          itemOrdenServicio['operadores'] = lstOperadores;
        });

        FormOptimizer.formEnable(this.formComponents);
      }, error => {
        FormOptimizer.formEnable(this.formComponents);
        console.log(error);
      });
  }

  public ordenServicioNuevo_clickEvent() : void {
    let objDialog = this.objMatDialog.open(OrdenesServiciosCreateComponent, {
      width         : '98%',
      maxWidth      : '1300px',
      minWidth      : '800px',
      height        : '96%',
      maxHeight     : '640px',
      data          : this.objOrdenPedido,
      // maxHeight     : '900px',
      disableClose  : true
    });

    objDialog.componentInstance.onClose.subscribe( ( itemCotizacionCreateComponent: OrdenesServiciosCreateComponent) => {
        if(Globals.exist(itemCotizacionCreateComponent.objOrdenServicio) && Globals.exist(itemCotizacionCreateComponent.objOrdenServicio?.idOrdenServicio)) {
            this.verOrdenServicio_Contrato_clickEvent(itemCotizacionCreateComponent.objOrdenServicio);
        }
        this.init();
    });
  }

  public ordenservicioEliminar_clickEvent() : void {

  }

  public ordenServicioFinalizar_clickEvent() : void {
    let fdOrdenPedidoFinalize = new FormData();
    fdOrdenPedidoFinalize.append("idOrdenPedido", this.objOrdenPedido.idOrdenPedido + '');
    this.objOrdenesPedidosService.finalize(fdOrdenPedidoFinalize)
      .subscribe(objResponse => {
        this.objMatDialog.open(SuccessComponent, Globals.successConfig({titulo: 'Finalizar Orden de pedido', mensaje: 'La orden de pedido fue finalizada satisfactoriamente.', autoCloseDelay: 3000}));
        this.init();
        }, error => {
        console.log(error)
      });
  }

  public generarContrato_clickEvent() : void {
    let lstOrdenesServiciosFiltered = this.lstOdernesServicios.filter( itemOrdenServicio => itemOrdenServicio.checked ).map(itemOrdenServicio => {return {checked : itemOrdenServicio.checked, idOrdenServicio : itemOrdenServicio.idOrdenServicio, folio : itemOrdenServicio.folio}});

    let objDialog = this.objMatDialog.open(OrdenPedidoGenerateAgreementComponent, {
      width         : '98%',
      maxWidth      : '1300px',
      height        : '96%',
      maxHeight     : '640px',
      data          : {
        ordenPedido      : this.objOrdenPedido,
        ordenesServicios : lstOrdenesServiciosFiltered
      },
      disableClose  : true
    });
  }

  public initUnidadesSelected() : void {
    this.lstOdernesServicios.forEach((itemOrdenServicio : any) => itemOrdenServicio['checked'] = false);
    this.boolChkOrdenServicio = false;
    this.boolChkSomeOrdenServicio = false;
  }

  public chkOrdenServicioSelected_changeEvent(event : any ) : void {
    this.boolChkOrdenServicio = event;
    this.lstOdernesServicios.forEach((itemOrdenServicio : any) => itemOrdenServicio.checked = this.boolChkOrdenServicio);
    this.boolChkSomeOrdenServicio = this.lstOdernesServicios.some((itemOrdenServicio : any) => itemOrdenServicio.checked);
    this.boolChkHasContrato       = this.lstOdernesServicios.filter( itemOrdenServicio => itemOrdenServicio.checked ).every((itemOrdenServicio : any) => !itemOrdenServicio.hasContrato);
  }

  public chkItemOrdenServicioSelected_changeEvent() : void {
    this.boolChkOrdenServicio = this.lstOdernesServicios.every((itemOrdenServicio : any) => itemOrdenServicio.checked);
    this.boolChkSomeOrdenServicio = this.lstOdernesServicios.some((itemOrdenServicio : any) => itemOrdenServicio.checked);
    this.boolChkHasContrato       = this.lstOdernesServicios.filter( itemOrdenServicio => itemOrdenServicio.checked ).every((itemOrdenServicio : any) => !itemOrdenServicio.hasContrato);
  }

  public verPdf_clickEvent(objOrdenServicio : any) {
    let objDialog = this.objMatDialog.open(FileViewerComponent, {
      width: '100%',
      maxWidth: '100%',
      height: '100%',
      maxHeight: '100%',
      data: {
        extension : 'pdf',
        fileName : objOrdenServicio.folio + '.pdf',
        pdfSrc : this.objApplicationConfig.ENDPOINT + 'api/ordenes-servicios/pdf/' + objOrdenServicio.idOrdenServicio
      },
      backdropClass: 'backdropBackground',
      panelClass : 'visualizarPanelClass',
      disableClose: true
    });

    objDialog.componentInstance.onDownload.subscribe( () => {
      this.objOrdenesServiciosService.createPdf(objOrdenServicio.idOrdenServicio)
        .subscribe(objResponse => {
          saveAs(objResponse, objOrdenServicio.folio + '.pdf');
        }, error => {

        });
    });

    objDialog.componentInstance.onPrint.subscribe( () => {
      this.objOrdenesServiciosService.createPdf(objOrdenServicio.idOrdenServicio)
        .subscribe(objResponse => {
          let blob = new Blob([objResponse], {type: 'application/pdf'});
          let blobUrl = URL.createObjectURL(blob);
          const iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          iframe.src = blobUrl;
          document.body.appendChild(iframe);
          if(iframe.contentWindow != null) {
            iframe.contentWindow.focus();
            iframe.contentWindow.print();
          }
        }, error => {

        });
    });
  }

  public verContrato_clickEvent(objOrdenServicio : any) {
    let objDialog = this.objMatDialog.open(FileViewerComponent, {
      width: '100%',
      maxWidth: '100%',
      height: '100%',
      maxHeight: '100%',
      data: {
        extension : 'pdf',
        fileName : objOrdenServicio.folio + '.pdf',
        pdfSrc : this.objApplicationConfig.ENDPOINT + 'api/ordenes-servicios/contrato/' + objOrdenServicio.idOrdenServicio
      },
      backdropClass: 'backdropBackground',
      panelClass : 'visualizarPanelClass',
      disableClose: true
    });

    objDialog.componentInstance.onDownload.subscribe( () => {
      this.objOrdenesServiciosService.contratoPdf(objOrdenServicio.idOrdenServicio)
        .subscribe(objResponse => {
          saveAs(objResponse, objOrdenServicio.folio + '.pdf');
        }, error => {

        });
    });

    objDialog.componentInstance.onPrint.subscribe( () => {
      this.objOrdenesServiciosService.contratoPdf(objOrdenServicio.idOrdenServicio)
        .subscribe(objResponse => {
          let blob = new Blob([objResponse], {type: 'application/pdf'});
          let blobUrl = URL.createObjectURL(blob);
          const iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          iframe.src = blobUrl;
          document.body.appendChild(iframe);
          if(iframe.contentWindow != null) {
            iframe.contentWindow.focus();
            iframe.contentWindow.print();
          }
        }, error => {

        });
    });
  }

    public verOrdenServicio_Contrato_clickEvent(objOrdenServicio : any) {
        let objDialog = this.objMatDialog.open(FileViewerComponent, {
            width: '100%',
            maxWidth: '100%',
            height: '100%',
            maxHeight: '100%',
            data: {
                extension : 'pdf',
                fileName : objOrdenServicio.folio + '.pdf',
                pdfSrc : this.objApplicationConfig.ENDPOINT + 'api/ordenes-servicios/concat/' + objOrdenServicio.idOrdenServicio + '/pdf'
            },
            backdropClass: 'backdropBackground',
            panelClass : 'visualizarPanelClass',
            disableClose: true
        });

        objDialog.componentInstance.onDownload.subscribe( () => {
            this.objOrdenesServiciosService.ordenServicio_contratoPdf(objOrdenServicio.idOrdenServicio)
                .subscribe(objResponse => {
                    saveAs(objResponse, objOrdenServicio.folio + '.pdf');
                }, error => {

                });
        });

        objDialog.componentInstance.onPrint.subscribe( () => {
            this.objOrdenesServiciosService.ordenServicio_contratoPdf(objOrdenServicio.idOrdenServicio)
                .subscribe(objResponse => {
                    let blob = new Blob([objResponse], {type: 'application/pdf'});
                    let blobUrl = URL.createObjectURL(blob);
                    const iframe = document.createElement('iframe');
                    iframe.style.display = 'none';
                    iframe.src = blobUrl;
                    document.body.appendChild(iframe);
                    if(iframe.contentWindow != null) {
                        iframe.contentWindow.focus();
                        iframe.contentWindow.print();
                    }
                }, error => {

                });
        });
    }

  public btnFinalizarOrdenServicio_clickEvent(itemOrdenServicio : any) : void {
    let objDialog = this.objMatDialog.open(OrdenesServiciosFinalizarComponent, {
      width         : '98%',
      maxWidth      : '98%',
      minWidth      : '800px',
      height        : '96%',
      data          : itemOrdenServicio,
      // maxHeight     : '900px',
      disableClose  : true
    });

    objDialog.componentInstance.onClose.subscribe( (objOrdenesServiciosFinalizarComponent : OrdenesServiciosFinalizarComponent) => {
        if(objOrdenesServiciosFinalizarComponent.isFinalize) {
            this.objMatDialog.open(ConfirmComponent, Globals.successConfig({titulo: 'Finalizar orden de pedido', mensaje: 'Ha finalizado las ordenes de servicios.\n¿Desea finalizar la orden de pedido: ' + this.objOrdenPedido.folio + '?', fnAccept: () => {
                this.ordenServicioFinalizar_clickEvent();
            }}));
        } else {
            this.init();
        }
    });
  }

  public btnPagoOrdenServicio_clickEvent(itemOrdenServicio : any) : void {
    let objDialog = this.objMatDialog.open(OrdenesServiciosPaymentComponent, {
      width         : '76%',
      maxWidth      : '450px',
      height        : '76%',
      maxHeight     : '250px',
      data          : itemOrdenServicio,
      // maxHeight     : '900px',
      disableClose  : true
    });

    objDialog.componentInstance.onClose.subscribe( () => {
      this.init();
    });
  }

  public checkContratoOrdenServicio_clickEvent(itemOrdenServicio : any) : void {
    this.objMatDialog.open(ConfirmComponent, Globals.successConfig({titulo: 'Contrato de orden de servicio', mensaje: '¿Desea ' + ((itemOrdenServicio?.hasContrato)?'deshabilitar el':'habilitar un') + ' contrato de la orden de servicio?',
      fnAccept: () => {
        let boolContrato : any = {value : !itemOrdenServicio.hasContrato};
        let fdOrdenServicioFinalize = new FormData();
        fdOrdenServicioFinalize.append("idOrdenServicio" , FormOptimizer.formDataNumber(itemOrdenServicio.idOrdenServicio));
        fdOrdenServicioFinalize.append("contrato"        , FormOptimizer.formDataBoolean(boolContrato));

        this.objOrdenesServiciosService.contrato(fdOrdenServicioFinalize)
          .subscribe(objResponse => {
            if( objResponse.action) {
              this.objMatDialog.open(SuccessComponent, Globals.successConfig({titulo: objResponse.title, mensaje: objResponse.message, autoCloseDelay: 3000}));
              this.init();
            } else
              this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: objResponse.title, mensaje: objResponse.message}));
          }, error => {
            console.log(error)
          });
      }}));
  }

  public close(): void {
    this.objDialogRef.close();
    this.onClose.emit(this);
  }

  public menuContextual_event(event: MouseEvent, itemOrdenServicio : any) : void {
    event.preventDefault();
    this.contextMenu.show(event);

    let boolOrdenServicioEstatus = (itemOrdenServicio.idOrdenServicioEstatus == 3);

    this.items = [
      {
        label:'ver PDF orden de servicio',
        icon:'pi pi-fw pi-file-pdf',
        disabled: !this.objPrivilegios.ORDENES_SERVICIOS_VIEW_PDF,
        command: (event) => this.verPdf_clickEvent(itemOrdenServicio)
      }, {
        label:'Ver contrato',
        icon:'pi pi-fw pi-eye',
        visible: itemOrdenServicio.hasContrato,
        disabled: !this.objPrivilegios.ORDENES_SERVICIOS_VIEW_PDF,
        command: () => this.verContrato_clickEvent(itemOrdenServicio)
      }, {
        label:'Finalizar orden de servicio',
        icon:'pi pi-fw pi-file',
        disabled: !this.objPrivilegios.ORDENES_SERVICIOS_FINALIZE,
        visible: !boolOrdenServicioEstatus,
        command: () => this.btnFinalizarOrdenServicio_clickEvent(itemOrdenServicio)
      }, {
        label:'Monto del servicio',
        icon:'pi pi-fw pi-dollar',
        // visible: !boolOrdenServicioEstatus,
        // disabled: !this.objPrivilegios.ORDENES_SERVICIOS_FINALIZE,
        command: () => this.btnPagoOrdenServicio_clickEvent(itemOrdenServicio)
      }
    ];
  }
}
