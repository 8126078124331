export class ApplicationConfig {

  // C
  public static readonly CREDENTIALS_USER : string = 'angularapp';
  public static readonly CREDENTIALS_PASS : string = '$_D3LT4_4PPS_@';

  public static readonly APP_VERSION : string ='1.1.0';

  // E
    // lOCAL
    // public static readonly ENDPOINT : string = 'http://localhost:8080/';

   // PRODUCCIÓN
   // public static readonly ENDPOINT : string = 'https://monarca.ws.deltaapps.mx/';

  // JGV Maquinaría
  public static readonly ENDPOINT : string = 'https://jgvmaquinaria.ws.deltaapps.mx/';

   // DEMO
   // public static readonly ENDPOINT : string = 'https://demo.ws.deltaapps.mx/';

   // CAMVO
   // public static readonly ENDPOINT : string = 'https://camvo.ws.deltaapps.mx/';
}
