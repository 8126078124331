import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {ApplicationConfig} from "../../libraries/application-config";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {PDFDocumentProxy} from "ng2-pdf-viewer";
import {Globals} from "../../libraries/globals";

@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss']
})
export class FileViewerComponent implements OnInit {

  @Output('onClose') onClose : EventEmitter<FileViewerComponent> = new EventEmitter<FileViewerComponent>();
  @Output('onDownload') onDownload : EventEmitter<FileViewerComponent> = new EventEmitter<FileViewerComponent>();
  @Output('onPrint') onPrint : EventEmitter<FileViewerComponent> = new EventEmitter<FileViewerComponent>();

  public idUsuarioFichero : number = 0;
  public fileName : string = '';
  public objApplicationConfig = ApplicationConfig;
  public zoom : number = 1;
  public page : number = 1;
  public totalPage : number = 0;
  public zoomScale : 'page-height' | 'page-fit' | 'page-width' = "page-height";
  public pdfSrc : any = '';
  public extension : string = ''
  public isFull    : boolean = false;

  public objLoader = {
    type            : 'loader',
    visible         : false
  }

  constructor(
    private objDialogRef: MatDialogRef<FileViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.extension = this.data.extension;
    this.fileName = this.data.fileName;

    if (Globals.exist(this.data.isBlob) && this.data.isBlob) {
      let objFileReader = new FileReader();
      objFileReader.readAsDataURL(this.data.pdfSrc);
      objFileReader.onload = (_event) => {
        this.pdfSrc = objFileReader.result || '';
      }
    } else {
      this.pdfSrc = this.data.pdfSrc;
    }
  }

  ngOnInit(): void {}

  public close() : void {
    this.objDialogRef.close();
    this.onClose.emit(this);
  }

  public btnUsuarioFicheroDownload_clickEvent() : void {
    this.onDownload.emit(this);
  }

  public btnUsuarioFicheroPrint_clickEvent() : void {
    this.onPrint.emit(this);
  }

  public zoomInit() : void {
    this.zoom = 1;
  }

  public zoomFull() : void {
    this.zoom = 2.5;
  }

  public zoomIn() : void {
    this.zoom = this.round(this.zoom + 0.5);
  }

  public zoomOut() : void {
    this.zoom = this.round(this.zoom - 0.5);
  }

  callBackFn(pdf: PDFDocumentProxy) {
    this.totalPage = pdf.numPages;
  }

  public round(num : number, decimales : number = 2) : number {
    let signo : number = (num >= 0 ? 1 : -1);
    let valor : string [] = [];
    num = num * signo;
    if (decimales === 0) //con 0 decimales
      return signo * Math.round(num);
    // round(x * 10 ^ decimales)
    valor = num.toString().split('e');
    num = Math.round(+(valor[0] + 'e' + (valor[1] ? (+valor[1] + decimales) : decimales)));
    // x * 10 ^ (-decimales)
    valor = num.toString().split('e');
    return signo * Number(valor[0] + 'e' + (valor[1] ? (+valor[1] - decimales) : -decimales));
  }
}
