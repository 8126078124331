import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuItems } from '../../../shared/menu-items/menu-items';
import { AuthService } from 'src/app/services/auth.service';
import { ApplicationConfig } from 'src/app/libraries/application-config';
import { NavigationEnd, Router } from '@angular/router';
import { Globals } from 'src/app/libraries/globals';
import { CookiesService } from 'src/app/services/cookies.service';
import {UsuariosCreateComponent} from "../../../cp/views/usuarios/create/create.component";
import {CuentaIndexComponent} from "../../../cp/views/cuenta/index/index.component";
import {MatDialog} from "@angular/material/dialog";
import {WebSocketAPI} from "../../../libraries/websocketapi";


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class AppSidebarComponent implements OnDestroy, AfterViewInit {
  // public config: PerfectScrollbarConfigInterface = {};
  // mobileQuery: MediaQueryList;

  // private _mobileQueryListener: () => void;
  // status = true;

  itemSelect: number[] = [];
  // parentIndex = 0;
  // childIndex = 0;

  setClickedRow(i: number, j: number) {
    // this.parentIndex = i;
    // this.childIndex = j;
  }
  subclickEvent() {
    // this.status = true;
  }
  scrollToTop() {
    // document.querySelector('.page-wrapper')?.scroll({
    //   top: 0,
    //   left: 0
    // });
  }

  @Input('toogle') toogle : boolean = true;
  public objUsuario : any;
  public objApplicationConfig : any;
  public lstMenu : Array<any> = [];
  public boolInicioActive : boolean = true;

  // @Output('onMenuSelect') onMenuSelect : EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public objMatDialog: MatDialog,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    public objAuthService : AuthService,
    public objRouter : Router,
    public objCookiesService : CookiesService,
    private webSocketAPI              : WebSocketAPI
  ) {
    this.objUsuario = this.objAuthService.usuario;
    this.objApplicationConfig = ApplicationConfig;
    this.lstMenu = this.objAuthService.menu;
    for(let index = 0; index < this.lstMenu.length; index++) {
      this.lstMenu[index]['open'] = false;
    }

    objRouter.events.subscribe((objNavigationEnd) => {
      if(objNavigationEnd instanceof NavigationEnd) {
        // objNavigationEnd.url.includes()
        this.objRouter_changeEvent();
      }
    });
    // this.objRouter_changeEvent();
  }

  public objRouter_changeEvent() {
    if( Globals.exist(this.lstMenu)) {
      for(let i = 0; i < this.lstMenu.length; i++) {
        let open : boolean = false;
        for( let j = 0; j < this.lstMenu[i].privilegios.length; j++) {
          if( this.objRouter.url.includes(this.lstMenu[i].privilegios[j].url) ) {
            open = true;
            this.boolInicioActive = false;
            break;
          }
        }
        this.lstMenu[i].open = open;
      }
    } else {
      this.objAuthService.logout();
    }
    // console.log()
  }

  ngAfterViewInit() : void {

  }

  ngOnDestroy(): void {
    // tslint:disable-next-line: deprecation
    // this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  public btnMiCuenta_click() : void {
    let objDialog = this.objMatDialog.open(CuentaIndexComponent, {
      width         : '96%',
      maxWidth      : '800px',
      height        : '96%',
      maxHeight     : '900px',
      disableClose  : true
    });

    objDialog.componentInstance.onClose.subscribe( ( itemUsuarioCreateComponent : CuentaIndexComponent) => {

    });
  }

  public btnCerrarSesion_clickEvent() : void {
    // this.webSocketAPI._disconnect();
    this.objAuthService.logout();
  }

  public ocpInicioActive_clickEvent() : void {
    this.lstMenu.forEach(itemMenu => {
      itemMenu.open = false;
    });
    this.boolInicioActive = true;
    this.objCookiesService.setBoolean('mainMenuToogle', true);
  }

  public opcMenu_clickEvent(index : number) : void {
    this.boolInicioActive = false;
    this.lstMenu.forEach(itemMenu => {
      itemMenu.open = false;
    });
    this.lstMenu[index].open = !this.lstMenu[index].open;
    this.objCookiesService.setBoolean('mainMenuToogle', true);
  }

  public opcMenuItem_clickEvent($event : any) : void {
    // $event.stopPropagation();
    // this.onMenuSelect.emit(this);
  }
}
