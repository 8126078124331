<div class="user-profile" style="background: url(assets/images/background/user-info.jpg) no-repeat;">
    <div class="profile-img" [class.open]="toogle">
        <div class="profile-img-foto" [style.background]="'url(' + objApplicationConfig.ENDPOINT + 'api/usuarios/foto/' + objUsuario.idUsuario + '/512' + ')'"></div>
    </div>

    <div class="profile-text">
        <a [matMenuTriggerFor]="sdprofile" class="usuario-nombre-completo"> <span *ngIf="toogle">{{objUsuario?.nombreCompleto}}</span>
            <i class="ti-angle-down font-12 m-l-5" [class.angle-toogle]="!toogle"></i>
        </a>
    </div>
    <mat-menu #sdprofile="matMenu" class="mymegamenu">
        <!-- <button mat-menu-item>
            <mat-icon>settings</mat-icon> Settings
        </button> -->
        <button mat-menu-item class="menu-item" (click)="btnMiCuenta_click()">
            <mat-icon>account_box</mat-icon> Mi cuenta
        </button>
        <!-- <button mat-menu-item>
            <mat-icon>notifications_off</mat-icon> Disable notifications
        </button> -->
        <button mat-menu-item class="menu-item" (click)="btnCerrarSesion_clickEvent()">
            <mat-icon>exit_to_app</mat-icon> Cerrar sesión
        </button>
    </mat-menu>
</div>

<div class="menu-list-items" [class.closed]="!toogle" >

  <div class="list-item" (click)="ocpInicioActive_clickEvent()">
    <a class="list-category" [class.active]="boolInicioActive" [routerLink]="['/cp/inicio']">
      <mat-icon class="item-icon">home</mat-icon>
      <span class="item-label" [hidden]="!toogle">Inicio</span>
      <span fxFlex></span>
    </a>
  </div>

    <div class="list-item" *ngFor="let itemMenu of lstMenu; let indexCategoria = index" (click)="opcMenu_clickEvent(indexCategoria)">
      <a class="list-category" [class.active]="itemMenu.open" href="javascript:;">
        <mat-icon class="item-icon">{{itemMenu.icono}}</mat-icon>
        <span class="item-label" [hidden]="!toogle">{{itemMenu.privilegioCategoria}}</span>
        <span fxFlex></span>
        <mat-icon *ngIf="toogle && !itemMenu.open" class="dd-icon">keyboard_arrow_down</mat-icon>
        <mat-icon *ngIf="toogle && itemMenu.open" class="dd-icon">keyboard_arrow_left</mat-icon>
      </a>

      <div class="sub-item item-menu" [hidden]="!toogle" *ngIf="itemMenu.privilegios.length > 0" [style.height.px]="(itemMenu.open)? itemMenu.privilegios.length * 35 : 0">
        <a *ngFor="let itemPrivilegio of itemMenu.privilegios; let indexPrivilegio=index;" routerLinkActive="menu-active" [routerLink]="['/' + itemPrivilegio.url]"  class="sub-item-label" (click)="opcMenuItem_clickEvent($event)">{{ itemPrivilegio.privilegio }}</a>
      </div>
    </div>

</div>
