import {AfterViewInit, Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ApplicationConfig} from "../../../../libraries/application-config";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {FormOptimizer} from "../../../../libraries/formOptimizer";
import {AlertComponent} from "../../../../components/alert/alert.component";
import {Globals} from "../../../../libraries/globals";
import {DatePipe} from "@angular/common";
import {SuccessComponent} from "../../../../components/success/success.component";
import {OrdenesServiciosService} from "../../../../services/ordenes-servicios.service";
import * as moment from 'moment';

@Component({
    selector: 'app-ordenes-servicios-create',
    templateUrl: './finalize.component.html',
    styleUrls: ['./finalize.component.scss']
})
export class OrdenesServiciosFinalizarComponent implements OnInit, AfterViewInit {

    @Output('onClose') onClose: EventEmitter<OrdenesServiciosFinalizarComponent> = new EventEmitter<OrdenesServiciosFinalizarComponent>();

    public fgOrdenServicioTiemposEjecucion: FormGroup = new FormGroup({
        programadaFecha     : new FormControl({value:'', disabled: true}),
        programadaHora      : new FormControl({value:'', disabled: true}),

        salidaOrigenFecha   : new FormControl({value:'', disabled: true}),
        salidaOrigenHora    : new FormControl({value:'', disabled: true}),
        salidaOrigenKms     : new FormControl({value:'', disabled: true}),
        salidaOrigenHrs     : new FormControl({value:'', disabled: true}),

        llegadaDestinoFecha : new FormControl('', Validators.required),
        llegadaDestinoHora  : new FormControl('', Validators.required),
        llegadaDestinoKms   : new FormControl(''),
        llegadaDestinoHrs   : new FormControl(''),

        salidaDestinoFecha  : new FormControl('', Validators.required),
        salidaDestinoHora   : new FormControl('', Validators.required),
        salidaDestinoKms    : new FormControl(''),
        salidaDestinoHrs    : new FormControl(''),

        llegadaOrigenFecha  : new FormControl('', Validators.required),
        llegadaOrigenHora   : new FormControl('', Validators.required),
        llegadaOrigenKms    : new FormControl(''),
        llegadaOrigenHrs    : new FormControl(''),
        observaciones       : new FormControl(''),
    });

    public Globals                            = Globals;
    public objApplicationConfig               = ApplicationConfig;
    public objOrdenPedido        : any        = {}
    public objOrdenServicio      : any        = {}
    public objCotizacion         : any        = {}
    public objScene              : any        = ["CLIENTE"];
    public lstCotizacionPartidas : Array<any> = [];
    public lstUnidadesMiembros   : Array<any> = [];
    public folio                 : string     = '';
    public isFinalize            : boolean    = false;
    public boolHorometro         : boolean = false;
    public boolOdometro          : boolean = false;

    public objLoader = {
        type: 'loader',
        visible: false
    };

    public objChkBox = {
        type: 'checkbox',
        disabled: false
    };

    public btnOptionBar = {
        type: 'button',
        disabled: false
    };

    public btnAccion = {
        type: 'button',
        disabled: false
    };

    public btnAceptar = {
        type: 'button',
        disabled: false
    };

    public btnCancelar = {
        type: 'button',
        disabled: false
    };

    public formComponents: Array<any> = [
        this.fgOrdenServicioTiemposEjecucion,
        this.btnOptionBar,
        this.btnAccion,
        this.objChkBox,
        this.objLoader,
        this.btnAceptar,
        this.btnCancelar
    ];

    constructor(
        private objOrdenesServiciosService: OrdenesServiciosService,
        private objDialogRef: MatDialogRef<OrdenesServiciosFinalizarComponent>,
        private objMatDialog: MatDialog,
        private objDatePipe: DatePipe,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    ngOnInit(): void {
        FormOptimizer.formDisable(this.formComponents);
    }

    ngAfterViewInit(): void {
        setTimeout( ()=> this.init() );
    }

    public init() : void {
        if (Globals.exist(this.data.folio)) this.folio = this.data.folio + ' - ';
        this.objOrdenesServiciosService.ending(this.data.idOrdenServicio)
            .subscribe(objResponse => {
                this.objOrdenPedido = objResponse.result?.ordenServicio;
                this.lstUnidadesMiembros = objResponse.result?.miembros;

                let programadaFecha   = moment(this.objOrdenPedido.programadaFechaIso ,"DD-MM-YYYY");
                let salidaOrigenFecha = moment(this.objOrdenPedido.salidaOrigenFechaIso ,"DD-MM-YYYY");
                this.fgOrdenServicioTiemposEjecucion.controls['programadaFecha'].setValue((programadaFecha.isValid())?programadaFecha:'');
                this.fgOrdenServicioTiemposEjecucion.controls['programadaHora'].setValue(this.objOrdenPedido.programadaHora);
                this.fgOrdenServicioTiemposEjecucion.controls['salidaOrigenFecha'].setValue((salidaOrigenFecha.isValid())?salidaOrigenFecha:'');
                this.fgOrdenServicioTiemposEjecucion.controls['salidaOrigenHora'].setValue(this.objOrdenPedido.salidaOrigenHora);
                this.fgOrdenServicioTiemposEjecucion.controls['salidaOrigenKms'].setValue(this.objOrdenPedido.salidaOrigenOdometro);
                this.fgOrdenServicioTiemposEjecucion.controls['salidaOrigenHrs'].setValue(this.objOrdenPedido.salidaOrigenHorometro);

              this.lstUnidadesMiembros.forEach( itemUnidadMiembro => {
                if (Globals.exist(itemUnidadMiembro?.isPrincipal) && itemUnidadMiembro.isPrincipal && Globals.exist(itemUnidadMiembro?.salidaOrigenOdometro)) this.boolOdometro = true;
                if (Globals.exist(itemUnidadMiembro?.isPrincipal) && itemUnidadMiembro.isPrincipal && Globals.exist(itemUnidadMiembro?.salidaOrigenHorometro)) this.boolHorometro = true;
              });

                FormOptimizer.formEnable(this.formComponents);
            }, error => {
                FormOptimizer.formEnable(this.formComponents);
                console.log(error);
            });
    }

    public formOrdenServicioCreate_submitEvent(): void {
        (<any>Object).values(this.fgOrdenServicioTiemposEjecucion.controls).forEach((itemControl: FormControl) => itemControl.markAsTouched());

        if (this.fgOrdenServicioTiemposEjecucion.valid) {
            this.fnProcessCreate();
        } else {
            this.objMatDialog.open(AlertComponent, Globals.confirmConfig({titulo: 'Error de formulario', mensaje: 'Los datos de la operación no están completos, complete la información.'}));
            this.objScene = ["OPERACION"];
        }
    }

    public fnProcessCreate(): void {

        let fdOrdenServicioFinalize = new FormData();
            fdOrdenServicioFinalize.append("idOrdenServicio"         , FormOptimizer.formDataNumber(this.data.idOrdenServicio));
            fdOrdenServicioFinalize.append("llegadaDestinoFecha"     , this.formatDateTime(this.fgOrdenServicioTiemposEjecucion.controls['llegadaDestinoFecha'].value, this.fgOrdenServicioTiemposEjecucion.controls['llegadaDestinoHora'].value));
            fdOrdenServicioFinalize.append("llegadaDestinoOdometro"  , FormOptimizer.formDataNumber(this.fgOrdenServicioTiemposEjecucion.controls['llegadaDestinoKms']));
            fdOrdenServicioFinalize.append("llegadaDestinoHorometro" , FormOptimizer.formDataNumber(this.fgOrdenServicioTiemposEjecucion.controls['llegadaDestinoHrs']));
            fdOrdenServicioFinalize.append("salidaDestinoFecha"      , this.formatDateTime(this.fgOrdenServicioTiemposEjecucion.controls['salidaDestinoFecha'].value, this.fgOrdenServicioTiemposEjecucion.controls['salidaDestinoHora'].value));
            fdOrdenServicioFinalize.append("salidaDestinoOdometro"   , FormOptimizer.formDataNumber(this.fgOrdenServicioTiemposEjecucion.controls['salidaDestinoKms']));
            fdOrdenServicioFinalize.append("salidaDestinoHorometro"  , FormOptimizer.formDataNumber(this.fgOrdenServicioTiemposEjecucion.controls['salidaDestinoHrs']));
            fdOrdenServicioFinalize.append("llegadaOrigenFecha"      , this.formatDateTime(this.fgOrdenServicioTiemposEjecucion.controls['llegadaOrigenFecha'].value, this.fgOrdenServicioTiemposEjecucion.controls['llegadaOrigenHora'].value));
            fdOrdenServicioFinalize.append("llegadaOrigenOdometro"   , FormOptimizer.formDataNumber(this.fgOrdenServicioTiemposEjecucion.controls['llegadaOrigenKms']));
            fdOrdenServicioFinalize.append("llegadaOrigenHorometro"  , FormOptimizer.formDataNumber(this.fgOrdenServicioTiemposEjecucion.controls['llegadaOrigenHrs']));
            fdOrdenServicioFinalize.append("observaciones"           , FormOptimizer.formDataString(this.fgOrdenServicioTiemposEjecucion.controls['observaciones']));

      this.lstUnidadesMiembros.forEach( (itemUnidadMiembro, index) => {
        fdOrdenServicioFinalize.append("idOrdenServicioMiembro" + index  , FormOptimizer.formDataNumber(itemUnidadMiembro.idOrdenServicioMiembro));
        fdOrdenServicioFinalize.append("llegadaDestinoOdometro" + index  , FormOptimizer.formDataNumber(itemUnidadMiembro.llegadaDestinoOdometro));
        fdOrdenServicioFinalize.append("llegadaDestinoHorometro" + index , FormOptimizer.formDataNumber(itemUnidadMiembro.llegadaDestinoHorometro));
        fdOrdenServicioFinalize.append("salidaDestinoOdometro" + index   , FormOptimizer.formDataNumber(itemUnidadMiembro.salidaDestinoOdometro));
        fdOrdenServicioFinalize.append("salidaDestinoHorometro" + index  , FormOptimizer.formDataNumber(itemUnidadMiembro.salidaDestinoHorometro));
        fdOrdenServicioFinalize.append("llegadaOrigenOdometro" + index   , FormOptimizer.formDataNumber(itemUnidadMiembro.llegadaOrigenOdometro));
        fdOrdenServicioFinalize.append("llegadaOrigenHorometro" + index  , FormOptimizer.formDataNumber(itemUnidadMiembro.llegadaOrigenHorometro));
      });

        FormOptimizer.formDisable(this.formComponents);
        this.objOrdenesServiciosService.finalize(fdOrdenServicioFinalize).subscribe(objResponse => {
          if( objResponse.action) {
            if(Globals.exist(objResponse.result?.isFinalize)) this.isFinalize = objResponse.result?.isFinalize;
            this.objOrdenServicio = objResponse.result.ordenServicio;
            this.close();
            this.objMatDialog.open(SuccessComponent, Globals.successConfig({titulo: objResponse.title, mensaje: objResponse.message, autoCloseDelay: 3000}));
          } else
            this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: objResponse.title, mensaje: objResponse.message}));
            FormOptimizer.formEnable(this.formComponents);
        }, error => {
            this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: 'Error de comunicación', mensaje: 'Ocurrió un error de comunicación con el servidor.', autoCloseDelay: 3000}));
            FormOptimizer.formEnable(this.formComponents);
        });

    }

    public inputLlegadaDestinoTime_keyUpEvent(event: any): void {
        if (event.target.value != '') {
            this.fgOrdenServicioTiemposEjecucion.controls['llegadaDestinoHora'].setValue(event.target.value);
        }
    }

    public inputSalidaDestinoTime_keyUpEvent(event: any): void {
        if (event.target.value != '') {
            this.fgOrdenServicioTiemposEjecucion.controls['salidaDestinoHora'].setValue(event.target.value);
        }
    }

    public inputLlegadaOrigenTime_keyUpEvent(event: any): void {
        if (event.target.value != '') {
            this.fgOrdenServicioTiemposEjecucion.controls['llegadaOrigenHora'].setValue(event.target.value);
        }
    }

    public boolValidationLlegadaDestinoOdometro(index: number) : boolean {
    let boolReturn = false;

    if (Globals.isStringNotEmpty(this.lstUnidadesMiembros[index]?.llegadaDestinoOdometro)) {
      if (Number(this.lstUnidadesMiembros[index].llegadaDestinoOdometro) < Number(this.lstUnidadesMiembros[index]?.salidaOrigenOdometro)) boolReturn = true;
    }

    return boolReturn;
  }

  public inputLlegadaDestinoOdometro_changeEvent(event: KeyboardEvent, index: number) : void {
      if (Globals.exist(this.lstUnidadesMiembros[index]?.isPrincipal) && this.lstUnidadesMiembros[index].isPrincipal) {
        let value = this.lstUnidadesMiembros[index].llegadaDestinoOdometro;
        this.fgOrdenServicioTiemposEjecucion.controls['llegadaDestinoKms'].setValue(value);
      }
  }

  public boolValidationSalidaDestinoOdometro(index: number) : boolean {
    let boolReturn = false;

    if (Globals.isStringNotEmpty(this.lstUnidadesMiembros[index]?.salidaDestinoOdometro)) {
      if (Number(this.lstUnidadesMiembros[index].salidaDestinoOdometro) < Number(this.lstUnidadesMiembros[index]?.salidaOrigenOdometro)) boolReturn = true;
      if (Number(this.lstUnidadesMiembros[index].salidaDestinoOdometro) < Number(this.lstUnidadesMiembros[index]?.llegadaDestinoOdometro)) boolReturn = true;
    }

    return boolReturn;
  }

  public inputSalidaDestinoOdometro_changeEvent(event: KeyboardEvent, index: number) : void {
    if (Globals.exist(this.lstUnidadesMiembros[index]?.isPrincipal) && this.lstUnidadesMiembros[index].isPrincipal) {
        let value = this.lstUnidadesMiembros[index].salidaDestinoOdometro;
        this.fgOrdenServicioTiemposEjecucion.controls['salidaDestinoKms'].setValue(value);
      }
  }

  public boolValidationLlegadaOrigenOdometro(index: number) : boolean {
      let boolReturn = false;

      if (Globals.isStringNotEmpty(this.lstUnidadesMiembros[index]?.llegadaOrigenOdometro)) {
        if (Number(this.lstUnidadesMiembros[index].llegadaOrigenOdometro) < Number(this.lstUnidadesMiembros[index]?.salidaOrigenOdometro)) boolReturn = true;
        if (Number(this.lstUnidadesMiembros[index].llegadaOrigenOdometro) < Number(this.lstUnidadesMiembros[index]?.llegadaDestinoOdometro)) boolReturn = true;
        if (Number(this.lstUnidadesMiembros[index].llegadaOrigenOdometro) < Number(this.lstUnidadesMiembros[index]?.salidaDestinoOdometro)) boolReturn = true;
      }

      return boolReturn;
  }

  public inputLlegadaOrigenOdometro_changeEvent(event: KeyboardEvent, index: number) : void {
    if (Globals.exist(this.lstUnidadesMiembros[index]?.isPrincipal) && this.lstUnidadesMiembros[index].isPrincipal) {
        let value = this.lstUnidadesMiembros[index].llegadaOrigenOdometro;
        this.fgOrdenServicioTiemposEjecucion.controls['llegadaOrigenKms'].setValue(value);
      }
  }

  public boolValidationLlegadaDestinoHorometro(index: number) : boolean {
    let boolReturn = false;

    if (Globals.isStringNotEmpty(this.lstUnidadesMiembros[index]?.llegadaDestinoHorometro)) {
      if (Number(this.lstUnidadesMiembros[index].llegadaDestinoHorometro) < Number(this.lstUnidadesMiembros[index]?.salidaOrigenHorometro)) boolReturn = true;
    }

    return boolReturn;
  }

  public inputLlegadaDestinoHorometro_changeEvent(event: KeyboardEvent, index: number) : void {
    if (Globals.exist(this.lstUnidadesMiembros[index]?.isPrincipal) && this.lstUnidadesMiembros[index].isPrincipal) {
        let value = this.lstUnidadesMiembros[index].llegadaDestinoHorometro;
        this.fgOrdenServicioTiemposEjecucion.controls['llegadaDestinoHrs'].setValue(value);
      }
  }

  public boolValidationSalidaDestinoHorometro(index: number) : boolean {
    let boolReturn = false;

    if (Globals.isStringNotEmpty(this.lstUnidadesMiembros[index]?.salidaDestinoHorometro)) {
      if (Number(this.lstUnidadesMiembros[index].salidaDestinoHorometro) < Number(this.lstUnidadesMiembros[index]?.salidaOrigenHorometro)) boolReturn = true;
      if (Number(this.lstUnidadesMiembros[index].salidaDestinoHorometro) < Number(this.lstUnidadesMiembros[index]?.llegadaDestinoHorometro)) boolReturn = true;
    }

    return boolReturn;
  }

  public inputSalidaDestinoHorometro_changeEvent(event: KeyboardEvent, index: number) : void {
    if (Globals.exist(this.lstUnidadesMiembros[index]?.isPrincipal) && this.lstUnidadesMiembros[index].isPrincipal) {
        let value = this.lstUnidadesMiembros[index].salidaDestinoHorometro;
        this.fgOrdenServicioTiemposEjecucion.controls['salidaDestinoHrs'].setValue(value);
      }
  }

  public boolValidationLlegadaOrigenHorometro(index: number) : boolean {
      let boolReturn = false;

      if (Globals.isStringNotEmpty(this.lstUnidadesMiembros[index]?.llegadaOrigenHorometro)) {
        if (Number(this.lstUnidadesMiembros[index].llegadaOrigenHorometro) < Number(this.lstUnidadesMiembros[index]?.salidaOrigenHorometro)) boolReturn = true;
        if (Number(this.lstUnidadesMiembros[index].llegadaOrigenHorometro) < Number(this.lstUnidadesMiembros[index]?.llegadaDestinoHorometro)) boolReturn = true;
        if (Number(this.lstUnidadesMiembros[index].llegadaOrigenHorometro) < Number(this.lstUnidadesMiembros[index]?.salidaDestinoHorometro)) boolReturn = true;
      }

      return boolReturn;
  }

  public inputLlegadaOrigenHorometro_changeEvent(event: KeyboardEvent, index: number) : void {
    if (Globals.exist(this.lstUnidadesMiembros[index]?.isPrincipal) && this.lstUnidadesMiembros[index].isPrincipal) {
        let value = this.lstUnidadesMiembros[index].llegadaOrigenHorometro;
        this.fgOrdenServicioTiemposEjecucion.controls['llegadaOrigenHrs'].setValue(value);
      }
  }

    public formDataDate(param: any): string {
        let objReturn = '';

        if (Globals.exist(param) && Globals.exist(param.value)) {
            objReturn = this.objDatePipe.transform(param.value, 'yyyy-MM-dd') + '';
        }

        if (objReturn == 'null') objReturn = '';

        return objReturn;
    }

    public formatDateTime(date: string, hora: string): string {
        let fecha: string = this.objDatePipe.transform(date, 'yyyy-MM-dd') + '';
        fecha = (fecha != '' && fecha != 'null' && fecha != null) ? fecha : '0000-00-00'

        hora = (hora != '' && hora != null) ? hora : '00:00';

        let dateTime = fecha + ' ' + hora;

        return dateTime;
    }

    public close(): void {
        this.objDialogRef.close();
        this.onClose.emit(this);
    }
}
