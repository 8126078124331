import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApplicationConfig } from '../libraries/application-config';
import { Sessions } from '../libraries/sessions';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class UnidadesMantenimientosImagenesService {

  constructor(  private http: HttpClient,
                private objAuthService : AuthService ) { }

  index( idUnidad : number ) : Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/mantenimientos/imagenes/" + idUnidad + "/index", Sessions.headerSession(this.objAuthService.access_token));
  }

  store(objUnidadImagen : any): Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + "api/mantenimientos/imagenes", objUnidadImagen, { headers: Sessions.headerFormData(this.objAuthService.access_token).headers, reportProgress: true, observe: 'events'}).pipe(map((event) => {

      switch (event.type) {
        case HttpEventType.UploadProgress:
          let total : any = event?.total;
          const progress = Math.round(100 * event.loaded / total);
          return {body : null, status: 'progress', uploadProgress: progress };

        case HttpEventType.Response:
          return {body : event.body, status: 'completed', uploadProgress: 100};
      }
    }));
  }

  download(idUnidadMantenimientoImagen : number): Observable<Blob> {
    return this.http.get<Blob>(ApplicationConfig.ENDPOINT + "api/mantenimientos/imagenes/" + idUnidadMantenimientoImagen + "/download", Sessions.headerFile(this.objAuthService.access_token));
  }

  zip(lstUnidadesImagenesSeleccionados : any): Observable<Blob> {
    return this.http.post<Blob>(ApplicationConfig.ENDPOINT + "api/mantenimientos/imagenes/zip", lstUnidadesImagenesSeleccionados, Sessions.headerFile(this.objAuthService.access_token));
  }

  delete(idUnidadMantenimientoImagen : number): Observable<any> {
    return this.http.put<any>(ApplicationConfig.ENDPOINT + "api/mantenimientos/imagenes/" + idUnidadMantenimientoImagen + "/delete" , Sessions.headerSession(this.objAuthService.access_token));
  }

  deleteList(lstUnidadesImagenesSeleccionados : any): Observable<any> {
    return this.http.put<any>(ApplicationConfig.ENDPOINT + "api/mantenimientos/imagenes/deleteSelected", lstUnidadesImagenesSeleccionados, Sessions.headerFormData(this.objAuthService.access_token));
  }
}
