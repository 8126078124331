<div class="pdf-header">
  <div class="title"> {{fileName}} </div>
  <div class="spacer"></div>
  <div class="buttons">
    <button type="button" *ngIf="extension == 'pdf'" class="title-button" matTooltip="Imprimir" (click)="btnUsuarioFicheroPrint_clickEvent()">
      <mat-icon class="title-button-icon">print</mat-icon>
    </button>
    <button type="button" class="title-button" matTooltip="Descargar" (click)="btnUsuarioFicheroDownload_clickEvent()">
      <mat-icon class="title-button-icon">save_alt</mat-icon>
    </button>
    <button type="button" class="title-button" matTooltip="Cerrar" (click)="close()">
      <mat-icon class="title-button-icon">clear</mat-icon>
    </button>
  </div>
</div>

<div *ngIf="extension == 'pdf'" class="mat-dialog-body mat-dialog-no-actions-title">
  <pdf-viewer
    class="pdf-viewer"
    [src]="pdfSrc"
    [render-text]="true"
    [zoom]="zoom"
    [zoom-scale]="zoomScale"
    [(page)]="page"
    [original-size]="false"
    (after-load-complete)="callBackFn($event)"
  ></pdf-viewer>
  <div class="pdf-footer" *ngIf="!objLoader.visible">
    <div class="pdf-item">
      <span style="margin-left:10px">Página</span>
      <span>{{page}}</span>
      <span>/</span>
      <span>{{totalPage}}</span>
    </div>
    <mat-divider [vertical]="true"></mat-divider>
    <div class="pdf-item">
      <button type="button" matTooltip="Alejar" matTooltipPosition="above" mat-icon-button [disabled]="!isFull && zoom <= 1" (click)="zoomOut()"><mat-icon>remove</mat-icon></button>
    </div>
    <div class="pdf-item">
      <button type="button" matTooltip="Ajustar al ancho" matTooltipPosition="above" *ngIf="zoom <= 1" mat-icon-button (click)="zoomFull()"><mat-icon>zoom_in</mat-icon></button>
      <button type="button" matTooltip="Restablecer zoom" matTooltipPosition="above" *ngIf="zoom > 1" mat-icon-button (click)="zoomInit()"><mat-icon>zoom_out</mat-icon></button>
    </div>
    <div class="pdf-item">
      <button type="button" matTooltip="Acercar" matTooltipPosition="above" mat-icon-button [disabled]="zoom>=4.5" (click)="zoomIn()"><mat-icon>add</mat-icon></button>
    </div>
  </div>
</div>

<div *ngIf="extension != 'pdf'" class="mat-dialog-body mat-dialog-no-actions-title">
    <div class="img-foto" [style.background]="'url(' + pdfSrc + ')'"></div>
</div>

<div class="mat-dialog-loader mat-dialog-no-actions">
  <mat-progress-bar class="loader" *ngIf="objLoader.visible" color="accent" mode="indeterminate"></mat-progress-bar>
</div>
