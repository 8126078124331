import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {ApplicationConfig} from '../libraries/application-config';
import {Globals} from '../libraries/globals';
import {Sessions} from '../libraries/sessions';

@Injectable({
  providedIn: 'root'
})
export class AuthService {


  constructor(  private http: HttpClient, /*,  @Inject(APP_BASE_HREF) private baseUrl: string */
                private objRouter: Router) { }

  geVersion(): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/auth/version", Sessions.header());
  }

  getPrivilegios(): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/auth/privilegios", Sessions.headerSession(this.access_token));
  }

  login( objParams : any): Observable<any> {
    let params = new URLSearchParams();
      params.set('grant_type', 'password');
      params.set('username', objParams.username);
      params.set('password', objParams.password);

    let response : any = this.http.post<any>(ApplicationConfig.ENDPOINT + "oauth/token", params.toString(), Sessions.headerOauth());
    return response;
  }

  public refresh() : Observable<any>  {
    let params = new URLSearchParams();
      params.set('grant_type', 'refresh_token');
      params.set('refresh_token', this.refresh_token);
    let response : any = this.http.post<any>(ApplicationConfig.ENDPOINT + "oauth/token", params.toString(), Sessions.headerOauth());
    return response;
  }

  public start( objResponse : any ) : void {
    sessionStorage.clear();
    let objSession : any = {};
    objSession.access_token = objResponse.access_token;
    objSession.expires_in = objResponse.expires_in;
    objSession.refresh_token = objResponse.refresh_token;
    objSession.token = JSON.parse(atob(objResponse.access_token.split('.')[1]));
    objSession.usuario = objSession.token.usuario;
    sessionStorage.setItem("session", JSON.stringify(objSession));
  }

  // private autoRefeshToken( expires_in : number ) : void {
  //   let performanceInit = performance.now();
  //   let $this = this;
  //   console.log("autoRefeshToken: " + expires_in);
  //   let expMilliseconds = expires_in * 1000;
  //   let time5Min = 5 * 60;
  //   let time4Min = 4 * 60;
  //   let time3Min = 3 * 60;
  //   let time2Min = 2 * 60;
  //   let time1Min = 60;
  //   let time20Seg = 20;
  //   if(expires_in > time5Min) {
  //     console.log("uno");
  //     let new_expires_in = expires_in - time5Min;
  //     console.log(new_expires_in);
  //     setTimeout(() => {
  //       console.log("uno - a");
  //       let timePerformance = (performance.now() - performanceInit) / 1000;
  //       $this.autoRefeshToken((expires_in - timePerformance));
  //     }, (new_expires_in * 1000));
  //   } else if(expires_in > time4Min) {
  //     console.log("dos " + expires_in);
  //     this.refresh().subscribe( objResponse => {
  //       console.log("dos - exito");
  //       this.start(objResponse);
  //     }, objError => {
  //       console.log("dos error");
  //       if(objError.status == 400 || objError.status == 401) {
  //         this.logout();
  //       } else {
  //         setTimeout(() => {
  //           console.log("dos - a");
  //           let timePerformance = (performance.now() - performanceInit) / 1000;
  //           $this.autoRefeshToken(timePerformance);
  //         }, time1Min);
  //       }
  //     });
  //   } else if(expires_in > 0) {
  //     console.log("tres " + expires_in);
  //     // this.refresh().subscribe( objResponse => {
  //     //   console.log("tres exito");
  //     //   this.start(objResponse);
  //     // }, objError => {
  //     //   if(objError.status == 400 || objError.status == 401) {
  //     //     console.log("tres error");
  //     //     this.logout();
  //     //   } else {
  //     //     setTimeout(() => {
  //     //       console.log("tres - a");
  //     //       let timePerformance = (performance.now() - performanceInit) / 1000;
  //     //       $this.autoRefeshToken(timePerformance);
  //     //     }, time20Seg);
  //     //   }
  //     // });
  //   } else {
  //     console.log("cuatro");
  //     this.logout();
  //   }
  // }

  public logout() : void {
    this.objRouter.navigate(['auth/login']);
    sessionStorage.removeItem('privilegios');
    sessionStorage.removeItem('session');
  }

  public account() : void {
    this.objRouter.navigate(['cp/account'])
  }

  public setPrivilegios( objResponse : any ) : void {
    let objSession : any = {};
    objSession.privilegios = objResponse.privilegios;
    objSession.menu        = objResponse.menu;
    sessionStorage.setItem("privilegios", JSON.stringify(objSession));
  }

  public get menu() : any {
    let objSession : any = this.generatePrivilegios();
    return objSession.menu;
  }

  public get privilegios() : any {
    let objSession : any = this.generatePrivilegios();
    return objSession.privilegios;
  }

  public get usuario() : any {
    let objSession : any = this.generateSession();
    return objSession.usuario;
  }

  public get access_token() : any {
    let objSession : any = this.generateSession();
    return objSession.access_token;
  }

  public get refresh_token() : any {
    let objSession : any = this.generateSession();
    return objSession.refresh_token;
  }

  private generateSession() : any {
    let strSession : string = sessionStorage.getItem("session")  || '{}';
    let objSession : any = JSON.parse(strSession);
    return objSession;
  }

  private generatePrivilegios() : any {
    let strSession : string = sessionStorage.getItem("privilegios")  || '{}';
    return JSON.parse(strSession);
  }

  public isAuthenticaded() : boolean {
    return Globals.exist(this.usuario);
  }
}
